import {
  Home,
  SupervisorAccount,
  LibraryBooks,
  NoteAdd,
  WebStories,
  School,
} from "@mui/icons-material";
import { SvgIcon } from "@mui/material";
import React, { ComponentType } from "react";

import Users from "../pages/Users";
// import Login from "../pages/Login";
// import Register from "../pages/Register";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";

import { PATH } from "./constants";
import Login from "../pages/Login";
import BlogPosts from "../pages/BlogPosts";
import Events from "../pages/Events";
import Pages from "../pages/Pages";
import EducationalResources from "../pages/EducationalResources";

type SvgIconComponent = typeof SvgIcon;

export type Route = {
  name?: string;
  icon?: SvgIconComponent;
  pathname: string;
  component: ComponentType<any>;
  permissions?: [];
  exact?: boolean;
  isDisabled?: boolean;
};

type SidebarSingleRoute = {
  name: string;
  icon?: SvgIconComponent;
  pathname?: string;
  permissions?: [];
  exact?: boolean;
};

export type SidebarRoute = SidebarSingleRoute &
  Partial<{ subRoutes: SidebarSingleRoute[] }>;

export type RouteType = Route & Partial<{ subRoutes: Route[] }>;

const TempComponent: React.FC = () => null;

export const SIDEBAR_ROUTES: SidebarRoute[] = [
  {
    name: "Events",
    icon: EditCalendarIcon,
    pathname: PATH.EVENTS,
  },
  {
    name: "Users",
    icon: SupervisorAccount,
    pathname: PATH.USERS,
  },
  {
    name: "Content",
    icon: NoteAdd,
    subRoutes: [
      {
        name: "Blog Posts",
        icon: LibraryBooks,
        pathname: PATH.BLOG_POSTS,
      },
      {
        name: "Pages",
        icon: WebStories,
        pathname: PATH.PAGES,
      },
      {
        name: "Educational Resources",
        icon: School,
        pathname: PATH.EDUCATIONAL_RESOURCES,
      },
    ],
  },
];

export const ROUTES: RouteType[] = [
  {
    name: "Home",
    icon: Home,
    pathname: PATH.ROOT,
    component: TempComponent,
  },
  {
    name: "Events",
    icon: EditCalendarIcon,
    pathname: PATH.EVENTS,
    component: Events,
  },
  {
    name: "Users",
    icon: SupervisorAccount,
    pathname: PATH.USERS,
    component: Users,
  },
  {
    name: "Blog Posts",
    icon: LibraryBooks,
    pathname: PATH.BLOG_POSTS,
    component: BlogPosts,
  },
  {
    name: "Pages",
    icon: WebStories,
    pathname: PATH.PAGES,
    component: Pages,
  },
  {
    name: "Educational Resources",
    icon: School,
    pathname: PATH.EDUCATIONAL_RESOURCES,
    component: EducationalResources,
  },
];

export const AUTH_ROUTES: RouteType[] = [
  {
    pathname: PATH.LOGIN,
    component: Login,
  },
  {
    pathname: PATH.REGISTER,
    component: Users,
  },
];
