import { Divider as MuiDivider } from '@mui/material';
import styled, { css, CSSProp } from 'styled-components';
import { ThemeProps } from '../../theme';

const Container = styled.div`
    padding: 16px 0;

    .MuiFormControl-root,
    .MuiFormControl-root {
        width: 100%;
    }
`;

const Divider = styled(MuiDivider)`
    ${({ theme }: ThemeProps): CSSProp =>
        css`
            margin: 30px;
        `}
`;

const ButtonsContainer = styled.div`
    display: flex;
    justify-content: flex-end;

    & > *:not(:last-child) {
        margin-right: 10px;
    }
`;

export const Styled = {
    Container,
    Divider,
    ButtonsContainer,
};
