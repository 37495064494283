import { Chip as MuiChip, ChipProps as MuiChipProps } from '@mui/material';
import styled, { css, CSSProp } from 'styled-components';

export enum ChipStatus {
    Success = 'success',
    Error = 'error',
    Info = 'info',
    Reject = 'reject',
}

type ChipProps = MuiChipProps &
    Readonly<{
        status?: ChipStatus;
    }>;

const checkStatus = (status: ChipStatus): CSSProp => {
    switch (status) {
        case ChipStatus.Success:
            return css`
                color: green;
                border-color: green;
            `;
        case ChipStatus.Info:
            return css`
                color: orange;
                border-color: orange;
            `;
        case ChipStatus.Reject:
            return css`
                color: purple;
                border-color: purple;
            `;
        case ChipStatus.Error:
            return css`
                color: red;
                border-color: red;
            `;
    }
};

const Chip = styled(MuiChip)<ChipProps>`
    min-width: 100px;

    ${({ status }) => status && checkStatus(status)}
`;

export const Styled = {
    Chip,
};
