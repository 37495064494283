import { Grid, Typography } from "@mui/material";
import { FormikProps, useFormikContext, withFormik } from "formik";
import { useEffect } from "react";

import ConfirmationButton from "../../components/ConfirmationButton";
import TextField from "../../components/TextField";

import { Styled } from "./EditEvent.styles";
import { validationSchema } from "./EditEvent.validation";
import {
  EditSportEventRequest,
  GetSportEventsResponse,
  SportEventCosts,
  SportEventStatus,
} from "@apps/sdk";
import { useGetCountriesQuery } from "../../query-hooks";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AddCircleOutlineOutlined } from "@mui/icons-material";
import StatusButtonWithText from "../../components/StatusButtonWithText";
import { mapEnumsToOptionsToTitleCase } from "../../utils";

type EditSportEventProps = Readonly<{
  data: GetSportEventsResponse["sportEvents"][0];
  onSubmit: (values: EditSportEventRequest) => void;
  isEdit: boolean;
}>;
type EditUserFormProps = FormikProps<EditSportEventRequest> &
  EditSportEventProps;

const EditSportEventForm: React.FC<EditUserFormProps> = (props) => {
  const {
    handleSubmit,
    values: {
      dateFrom,
      dateTo,
      keyResponsibilities,
      otherRequirements,
      status,
    },
    data,
    isEdit,
  } = props;
  const { setFieldValue, submitForm, initialValues } = useFormikContext();

  const { getCountries, data: countriesData } = useGetCountriesQuery();

  const costsOptions = mapEnumsToOptionsToTitleCase(SportEventCosts);

  const statusOptions = Object.values(SportEventStatus).map((value) =>
    value.toUpperCase()
  );

  useEffect(() => {
    getCountries({
      pagination: {
        page: 0,
        rowsPerPage: 100,
      },
    });
  }, []);

  const countryOptions = countriesData?.countries.map((country) => ({
    name: country.name,
    value: country.id,
  }));

  useEffect(() => {
    if (!data) {
      return;
    }

    setFieldValue(
      "dateFrom",
      data?.dateFrom ? new Date(parseInt(data?.dateFrom)) : new Date()
    );
    setFieldValue(
      "dateTo",
      data?.dateTo ? new Date(parseInt(data?.dateTo)) : new Date()
    );

    setFieldValue("status", data.status);
  }, [initialValues]);

  const handleCountryChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const selectedCountryValue = event.target.value;
    const selectedCountry =
      countryOptions &&
      countryOptions.find((country) => country.value === selectedCountryValue);
    setFieldValue("country", {
      id: selectedCountry?.value,
      name: selectedCountry?.name,
    });
    setFieldValue("countryId", selectedCountry?.value);
  };

  const handleStatusChange = (status: string): void => {
    setFieldValue("status", status);
  };

  return (
    <Styled.Container>
      <form onSubmit={handleSubmit} noValidate>
        <Grid container spacing={3} alignContent="center">
          <Grid item xs={4}>
            <TextField name="name" label="Event Title" required />
          </Grid>

          <Grid item xs={2}>
            <TextField
              name="costs"
              label="Costs"
              select
              options={costsOptions}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              name="description"
              label="Description"
              multiline
              rows={4}
              required
            />
          </Grid>

          <Grid item xs={3}>
            <DateTimePicker
              label="Start Time"
              onChange={(time) => {
                setFieldValue("dateFrom", time);
              }}
              value={new Date(dateFrom)}
            />
          </Grid>
          <Grid item xs={3}>
            <DateTimePicker
              label="End Time"
              onChange={(time) => setFieldValue("dateTo", time)}
              value={new Date(dateTo)}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              name="countryId"
              select
              label="Country"
              options={countryOptions}
              required
              onChange={(e) => handleCountryChange(e)}
            />
          </Grid>

          <Grid item xs={3}>
            <TextField name="city" label="City" />
          </Grid>

          <Grid item xs={12}>
            <Styled.Divider />
          </Grid>

          <Grid item xs={6}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography>Key Responsibilies</Typography>
              </Grid>

              {keyResponsibilities.list?.map((item, index) => (
                <Grid item xs={12} key={index}>
                  <TextField
                    name={`keyResponsibilities['list'].${index}`}
                    label={`Key Responsibility #${index + 1}`}
                  />
                </Grid>
              ))}

              <Grid item xs={12}>
                <Styled.AddButton
                  variant="text"
                  disableElevation
                  onClick={() =>
                    setFieldValue("keyResponsibilities", {
                      list: [...(keyResponsibilities.list || []), ""],
                    })
                  }
                  startIcon={<AddCircleOutlineOutlined />}
                >
                  Add More
                </Styled.AddButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography>Other Requirements</Typography>
              </Grid>

              {otherRequirements.list?.map((item, index) => (
                <Grid item xs={12} key={index} spacing={3}>
                  <TextField
                    name={`otherRequirements['list'].${index}`}
                    label={`Requirement #${index + 1}`}
                  />
                </Grid>
              ))}

              <Grid item xs={12}>
                <Styled.AddButton
                  variant="text"
                  disableElevation
                  onClick={() =>
                    setFieldValue("otherRequirements", {
                      list: [...(otherRequirements.list || []), ""],
                    })
                  }
                  startIcon={<AddCircleOutlineOutlined />}
                >
                  Add More
                </Styled.AddButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Styled.Divider />

        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={6}>
            {isEdit && status && (
              <StatusButtonWithText
                type="event"
                selected={status}
                options={statusOptions}
                onChange={(e) => handleStatusChange(e)}
              />
            )}
          </Grid>

          <Grid item>
            <Styled.ButtonsContainer>
              <ConfirmationButton
                variant="contained"
                color="inherit"
                onClick={() => submitForm()}
                dialogProps={{
                  title:
                    "Are you sure you want to save the changes for this user?",
                  proceed: "yes",
                  cancel: "no",
                }}
              >
                Save Changes
              </ConfirmationButton>
            </Styled.ButtonsContainer>
          </Grid>
        </Grid>
      </form>
    </Styled.Container>
  );
};

const EditSportEventFormWrapper = withFormik<
  EditSportEventProps,
  EditSportEventRequest
>({
  mapPropsToValues: ({ data }) => {
    const {
      id,
      name,
      city,
      country,
      description,
      dateFrom,
      dateTo,
      status,
      volunteersCount,
      approvedVolunteersCount,
      workingHoursCount,
      keyResponsibilities,
      otherRequirements,
      costs,
    } = data;

    return {
      id,
      name,
      description: description || "",
      country,
      city,
      dateFrom,
      dateTo,
      status,
      volunteersCount,
      approvedVolunteersCount,
      workingHoursCount,
      keyResponsibilities,
      otherRequirements,
      countryId: country.id || "",
      costs: costs || SportEventCosts.NotCovered,
    };
  },
  validationSchema,
  enableReinitialize: true,
  handleSubmit: (values, { props: { onSubmit } }) => {
    onSubmit(values);
  },
  displayName: "EditSportEventForm",
})(EditSportEventForm);

export default EditSportEventFormWrapper;
