import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

type HTMLEditorProps = {
  html: string;
  onChange: (html: string) => void;
};

const HTMLEditor: React.FC<HTMLEditorProps> = ({ html, onChange }) => (
  <ReactQuill theme="snow" value={html} onChange={onChange} />
);

export default HTMLEditor;
