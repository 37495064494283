import {
  CreateOrEditBlogRequest,
  CreateOrEditBlogResponse,
  Query,
} from "@apps/sdk";
import { useCallback } from "react";
import { useQueryState } from "./common";
import { frontofficeService } from "../services";
import { ToastMessageType, addToast } from "../utils";
import { toast } from "react-toastify";

export const useCreateOrEditBlogsQuery = () => {
  const { data, error, loading, setState, setLoading } =
    useQueryState<CreateOrEditBlogResponse>();

  const createOrEditBlogs = useCallback(
    async (payload: CreateOrEditBlogRequest) => {
      setLoading(true);

      setState(
        await frontofficeService.query({
          query: Query.CreateOrEditBlog,
          payload: payload,
          onSuccess: (response: CreateOrEditBlogResponse) => {
            toast.success("Blog Post updated", {
              position: "top-right",
            });
          },
          onError: (errorResponse: any) => {
            errorResponse.errors.forEach(
              (error: any) => toast.error(error.message),
              {
                position: "top-right",
              }
            );
          },
        })
      );

      setLoading(false);
    },
    []
  );

  return {
    createOrEditBlogs,
    data,
    error,
    loading,
  };
};
