import styled from 'styled-components';

const NameContainer = styled.div`
    margin-top: 30px;
`;

const Name = styled.div`
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 5px;
`;

const Description = styled.div`
    display: flex;
    align-items: center;
    font-size: 12px;
    color: darkgreen;

    .MuiSvgIcon-root {
        width: 16px;
        margin-right: 5px;
    }
`;

export const Styled = {
    NameContainer,
    Name,
    Description,
};
