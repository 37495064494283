import React, { useState, ChangeEvent, useRef } from "react";
import { Styled } from "./ImageUploader.styles";
import { Button } from "@mui/material";

interface ImageUploaderProps {
  onImageUpload: (file: File) => void;
  initialImage?: string | null; // Initial image path for editing
  buttonText?: string; // Custom button text
}

const ImageUploader: React.FC<ImageUploaderProps> = ({
  onImageUpload,
  initialImage,
  buttonText = "Upload Image",
}) => {
  const [image, setImage] = useState<string | null>(initialImage || null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    const fileInput = e.target;
    if (!fileInput || !fileInput.files || !fileInput.files[0]) {
      return;
    }

    const file = fileInput.files[0];

    if (file) {
      const allowedFormats = [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "image/svg+xml",
      ];
      if (allowedFormats.includes(file.type)) {
        const reader = new FileReader();

        reader.onload = (event) => {
          const result = event.target?.result as string;
          if (result) {
            setImage(result);
          }
        };

        reader.readAsDataURL(file);
        onImageUpload(file);
      } else {
        alert(
          "Unsupported file format. Please choose a JPEG, JPG, PNG, or SVG file."
        );
      }
    }
  };

  const openFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div>
      <input
        type="file"
        accept=".jpeg, .jpg, .png, .svg"
        onChange={handleImageChange}
        style={{ display: "none" }}
        ref={fileInputRef}
      />
      <Button onClick={openFileInput} variant="outlined">
        {buttonText}
      </Button>
      {image && <Styled.UploadedImage src={image} alt="Uploaded Image" />}
    </div>
  );
};

export default ImageUploader;
