import { State } from "@packages/core-shared";
import { Header, JwtResponse } from "@apps/sdk";
import { jsonUtils } from "../utils";
import { frontofficeService } from "../services";

const STORAGE_KET = 'token';

const persistedTokenData = localStorage.getItem(STORAGE_KET);

export const tokenDataState = new State<JwtResponse | undefined>(persistedTokenData ? jsonUtils.parse<JwtResponse>(persistedTokenData) : undefined)

setTokenToAuthorizationHeader(tokenDataState.get())

tokenDataState.addChangeListener((_, newState) => {
  persistTokenToLocalStorage(newState);

  setTokenToAuthorizationHeader(newState)
})

function persistTokenToLocalStorage(tokenData: JwtResponse | undefined) {
  tokenData ? localStorage.setItem(STORAGE_KET, JSON.stringify(tokenData)) : localStorage.removeItem(STORAGE_KET)
}

function setTokenToAuthorizationHeader(tokenData: JwtResponse | undefined) {
  tokenData ? frontofficeService.appendHeader(Header.Authorization, tokenData.token) : frontofficeService.removeHeader(Header.Authorization);
}
