import EditEditEducationalResourceForm, {
  DEFAULT_VALUES,
} from "./EditEducationalResourceForm";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import {
  CreateOrEditEducationResourceRequest,
  EducationResourceType,
} from "@apps/sdk";
import { useNavigate } from "react-router-dom";
import { PATH } from "../../routes";
import {
  useCreateOrEditEducationResourceQuery,
  useGetEducationalResourcesQuery,
} from "../../query-hooks";
import { toast } from "react-toastify";

const CreateOrEditEducationalResource: React.FC = () => {
  const { resourceId } = useParams<{ resourceId: string }>();
  const isEdit = !!resourceId;

  const { getEducationalResources, data: resources } =
    useGetEducationalResourcesQuery();

  const { createOrEditEducationResource, data: updatedResource } =
    useCreateOrEditEducationResourceQuery();

  const navigate = useNavigate();

  useEffect(() => {
    if (!resourceId) {
      return;
    }

    getEducationalResources({
      pagination: {
        page: 0,
        rowsPerPage: 1,
      },
      filter: {
        id: resourceId,
      },
    });
  }, [resourceId]);

  useEffect(() => {
    if (updatedResource?.id && !isEdit) {
      navigate(`${PATH.EDUCATIONAL_RESOURCES}/edit/${updatedResource.id}`);
    }
  }, [updatedResource]);

  const handleSubmit = (
    values: CreateOrEditEducationResourceRequest & { url?: string | undefined }
  ): void => {
    console.log(values);

    if (
      !values.file &&
      !values.url &&
      values.type === EducationResourceType.Document
    ) {
      toast.error("Please, select a file for upload.", {
        position: "top-right",
      });

      return;
    }
    const { url, ...rest } = values;

    createOrEditEducationResource(rest);
  };

  const data = updatedResource ||
    resources?.resources[0] || { ...DEFAULT_VALUES };

  return (
    <EditEditEducationalResourceForm
      data={data}
      onSubmit={handleSubmit}
      isEdit={isEdit}
    />
  );
};

export default CreateOrEditEducationalResource;
