import ClearIcon from "@mui/icons-material/Clear";
import { Button, Grid } from "@mui/material";
import { GridCellParams, GridColDef } from "@mui/x-data-grid";
import { useFormik } from "formik";
import React, { useEffect, useState, useCallback } from "react";
import { PATH } from "../../routes";
import { getFileSrc, mapEnumsToOptions } from "../../utils";
import { validateEmail } from "../../utils";

import Table from "../../components/Table";
import TableActions from "../../components/Table/TableActions";

import DropdownSelect from "../../components/Filter/DropdownSelect";
import { Styled } from "./Users.styles";
import { useGetUsersQuery } from "../../query-hooks";
import { UserStatus, UserType } from "@apps/sdk";
import StatusChip from "../../components/StatusChip";

function createData(
  id: string,
  fullName: string,
  email: string,
  country: { name: string; id: string },
  type: string,
  photoUrl: string,
  status: UserStatus
) {
  return { id, fullName, email, country, type, photoUrl, status };
}

const userTypeOptions = mapEnumsToOptions(UserType);

const UserTable: React.FC = () => {
  const { getUsers, data: users } = useGetUsersQuery();

  const [isSingleSearchInUse, setSingleSearchInUse] = useState(false);
  const [paginationCount, setPaginationCount] = useState(0);

  const [typeFilterValue, setTypeFilterValue] = useState({ type: "" });
  const [pageSize, setPageSize] = useState(10);

  const maxCount = users?.maxCount || 0;

  useEffect(() => {
    getUsers({
      pagination: {
        page: 0,
        rowsPerPage: pageSize,
      },
    });
  }, []);

  const filterForm = useFormik({
    initialValues: {
      type: "",
    },
    onSubmit: (values) => {
      setTypeFilterValue(values);
    },
  });

  useEffect(() => {
    if (typeFilterValue.type === "") {
      return;
    }

    getUsers({
      pagination: {
        page: 0,
        rowsPerPage: pageSize,
      },
      filter: {
        type:
          typeFilterValue.type === UserType.Volunteer
            ? UserType.Volunteer
            : UserType.SportOrganization,
      },
    });

    console.log(typeFilterValue);
  }, [typeFilterValue]);

  useEffect(() => {
    if (maxCount !== 0) {
      setPaginationCount(maxCount);
    }
  }, [maxCount]);

  useEffect(() => {
    if (maxCount === 0 || typeFilterValue.type === "") {
      return;
    }

    setPaginationCount(users?.users.length || 0);
  }, [users?.users.length, maxCount, typeFilterValue]);

  const handlePageChange = useCallback((page: number, rowsPerPage: number) => {
    setPageSize(rowsPerPage);
    getUsers({
      pagination: {
        page: page,
        rowsPerPage: rowsPerPage,
      },
    });
  }, []);

  const handleFilterByName = useCallback(
    (value: string | undefined) => {
      if (value) {
        setPaginationCount(1);

        setSingleSearchInUse(true);

        return;
      }

      setPaginationCount(maxCount);
      setSingleSearchInUse(false);
    },
    [maxCount]
  );

  const rows =
    users &&
    users.users.map((row: any) => {
      const email = validateEmail(row.email) ? row.email : "Not available";

      return createData(
        row.id,
        row.fullName,
        email,
        row.country?.name || "N/A",
        row.type,
        row.photoUrl,
        row.status
      );
    });

  const handleClearFilter = () => {
    getUsers({
      pagination: {
        page: 0,
        rowsPerPage: 10,
      },
    });

    setTypeFilterValue({ type: "" });
    filterForm.setFieldValue("type", "");
  };

  const renderTableActions = useCallback(
    (params: GridCellParams) => (
      <TableActions
        edit={{
          visible: true,
          page: PATH.USERS,
          path: params.row.id,
        }}
      />
    ),
    []
  );

  const renderStatus = useCallback(
    (params: GridCellParams) => <StatusChip status={params.value} />,
    []
  );

  const renderAvatar = useCallback((params: GridCellParams) => {
    const avatarImg = params.row.photoUrl;

    const avatar = avatarImg ? (
      <>
        <Styled.ImgAvatar src={getFileSrc(avatarImg)} /> {` ${params.value}`}
      </>
    ) : (
      <>
        <Styled.Avatar>
          <>{params.value && params.value.toString().charAt(0).toUpperCase()}</>
        </Styled.Avatar>{" "}
        {` ${params.value}`}
      </>
    );
    return avatar;
  }, []);
  const columns: GridColDef[] = [
    {
      field: "fullName",
      headerName: "Name",
      flex: 2,
      renderCell: renderAvatar,
    },
    { field: "email", headerName: "Email", flex: 2 },
    {
      field: "type",
      headerName: "Type",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "country",
      headerName: "Country",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: renderStatus,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "actions",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      headerName: "Actions",
      width: 130,
      renderCell: renderTableActions,
      align: "center",
      headerAlign: "center",
    },
  ];

  if (!rows) {
    return null;
  }

  return (
    rows && (
      <Styled.Container>
        <Styled.FiltersContainer>
          <form onSubmit={filterForm.handleSubmit}>
            <Grid container spacing={2} alignItems="center">
              {!isSingleSearchInUse && (
                <>
                  <Grid item xs={2}>
                    <DropdownSelect
                      data={userTypeOptions || []}
                      disabled={isSingleSearchInUse}
                      name="type"
                      onChange={(e: any) => {
                        setTypeFilterValue({ type: e.target.value });
                        filterForm.handleChange(e);
                      }}
                      label="User Type"
                      id="user-type-selector"
                      value={filterForm.values.type}
                    />
                  </Grid>

                  <Grid item xs={1}>
                    <Button
                      size="small"
                      disabled={isSingleSearchInUse}
                      variant="outlined"
                      color="secondary"
                      onClick={handleClearFilter}
                      startIcon={<ClearIcon />}
                    >
                      Clear
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>
          </form>
        </Styled.FiltersContainer>

        <Table
          page={0}
          rows={rows}
          columns={columns}
          pageSize={pageSize}
          onPageChange={handlePageChange}
          rowCount={paginationCount}
          hasActionButtons={true}
        />
      </Styled.Container>
    )
  );
};

export default UserTable;
