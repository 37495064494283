import { TextField as MuiTextField } from '@mui/material';
import styled, { css, CSSProp } from 'styled-components';

const TextField = styled(MuiTextField)`
    ${({ error }): CSSProp => css`
        .MuiTextField-root {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            width: 300px;
        }
    `}
`;

export const Styled = { TextField };
