import {
    Breadcrumbs as MuiBreadcrumbs,
    Link as MuiLink,
    LinkProps as MuiLinkProps,
    Typography as MuiTypography,
} from '@mui/material';
import { Link as RouterLink, LinkProps } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { ThemeProps } from '../../theme';

const Breadcrumbs = styled(MuiBreadcrumbs)`
    margin-bottom: 16px;
`;

const itemStyle = css`
    display: flex;
    align-items: center;

    .MuiSvgIcon-root {
        width: 20px;
        height: 20px;
        margin-right: 1px};
    }
`;

const LinkWithProps: React.FC<LinkProps & MuiLinkProps> = (props) => (
    <MuiLink component={RouterLink} color="inherit" underline="hover" {...props} />
);

const Link = styled(LinkWithProps)`
    ${itemStyle}
`;

const Typography = styled(MuiTypography)`
    ${itemStyle}
`;

export const Styled = {
    Breadcrumbs,
    Link,
    Typography,
};
