import { Grid } from "@mui/material";
import { FormikProps, useFormikContext, withFormik } from "formik";
import { useEffect } from "react";
import { getFileSrc, mapEnumsToOptionsToTitleCase, slugify } from "../../utils";

import ConfirmationButton from "../../components/ConfirmationButton";
import TextField from "../../components/TextField";

import { Styled } from "./EditEducationalResource.styles";
import { validationSchema } from "./EditEducationalResource.validation";
import {
  BlogStatus,
  CreateOrEditEducationResourceRequest,
  EducationResourceStatus,
  EducationResourceType,
  GetEducationResourcesResponse,
} from "@apps/sdk";
import { Upload } from "@packages/core-shared";
import DocumentUploader from "../../components/DocumentUploader/DocumentUploader";

export const DEFAULT_VALUES = {
  id: "",
  name: "",
  slug: "",
  type: EducationResourceType.Video,
  status: EducationResourceStatus.Hidden,
};

type EditPageProps = Readonly<{
  data: GetEducationResourcesResponse["resources"][0];
  onSubmit: (values: CreateOrEditEducationResourceRequest) => void;
  isEdit: boolean;
}>;
type EditBlogsFormProps = FormikProps<CreateOrEditEducationResourceRequest> &
  EditPageProps;

const EditPageForm: React.FC<EditBlogsFormProps> = (props) => {
  const { handleSubmit, data, values, isEdit } = props;
  const { setFieldValue, submitForm, initialValues } = useFormikContext();

  const { name, type } = values;
  const statusOptions = mapEnumsToOptionsToTitleCase(BlogStatus);

  const typeOptions = mapEnumsToOptionsToTitleCase(EducationResourceType);

  useEffect(() => {
    if (!data) {
      return;
    }

    setFieldValue("status", data?.status);
    setFieldValue("url", data?.url);
    type === EducationResourceType.Video &&
      data?.url &&
      setFieldValue("videoLink", data?.url);
  }, [initialValues]);

  useEffect(() => {
    setFieldValue("slug", slugify(name));
  }, [name, setFieldValue]);

  const handleStatusChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFieldValue("status", event.target.value);
  };

  if (isEdit && data.id === "") {
    return null;
  }

  const handleFileUpload = (file: File) =>
    setFieldValue("file", new Upload(file));

  if (isEdit && !data) {
    return null;
  }

  return (
    <Styled.Container>
      <form onSubmit={handleSubmit} noValidate>
        <Grid container spacing={3} alignContent="center">
          {!isEdit && (
            <Grid item xs={3}>
              <TextField
                name="type"
                options={typeOptions}
                label="Resource type"
                select
              />
            </Grid>
          )}
          {type === EducationResourceType.Document ? (
            <Grid item xs={4}>
              <DocumentUploader
                fileUrl={getFileSrc(data.url || "")}
                onFileUpload={handleFileUpload}
                acceptedTypes={[
                  "application/pdf",
                  "application/msword",
                  "text/plain",
                ]}
              />
            </Grid>
          ) : (
            <Grid item xs={9}>
              <TextField name="videoLink" label="YouTube Video Link" />
            </Grid>
          )}
        </Grid>

        <Styled.Divider />
        <Grid container spacing={3} alignContent="center">
          <Grid item xs={6}>
            <TextField name="name" label="Resource Name" required />
          </Grid>

          <Grid item xs={6}>
            <TextField name="slug" label="Slug" required />
          </Grid>

          <Grid item xs={12}>
            <TextField
              name="description"
              label="Descirption"
              minRows={5}
              multiline
              required
            />
          </Grid>
        </Grid>
        <Styled.Divider />
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={4}>
            {isEdit && (
              <TextField
                name="status"
                select
                label="Status"
                options={statusOptions}
                onChange={(e) => handleStatusChange(e)}
              />
            )}
          </Grid>

          <Grid item>
            <Styled.ButtonsContainer>
              <ConfirmationButton
                variant="contained"
                color="inherit"
                onClick={() => submitForm()}
                dialogProps={{
                  title:
                    "Are you sure you want to save the changes for this resource?",
                  proceed: "yes",
                  cancel: "no",
                }}
              >
                Save Changes
              </ConfirmationButton>
            </Styled.ButtonsContainer>
          </Grid>
        </Grid>
      </form>
    </Styled.Container>
  );
};

const EditPageFormWrapper = withFormik<
  EditPageProps,
  CreateOrEditEducationResourceRequest
>({
  mapPropsToValues: ({ data }) => {
    if (!data) {
      return DEFAULT_VALUES;
    }

    const { id, name, type, slug, status, imageUrl, url, description } = data;

    return {
      ...DEFAULT_VALUES,
      id,
      name,
      type,
      description,
      status,
      slug,
      imageUrl,
      url,
    };
  },
  validationSchema,
  enableReinitialize: true,
  handleSubmit: (values, { props: { onSubmit } }) => {
    onSubmit(values);
  },
  displayName: "EditPageForm",
})(EditPageForm);

export default EditPageFormWrapper;
