import EditTeamForm, { EditUserFormValues } from "./EditUserForm";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useEditUserQuery, useGetUsersQuery } from "../../query-hooks";

const EditUser: React.FC = () => {
  const { userId } = useParams<{ userId: string }>();
  const isEdit = !!userId;

  const { getUsers, data: users } = useGetUsersQuery();

  const { editUser, data: updatedUser } = useEditUserQuery();

  useEffect(() => {
    if (!userId) {
      return;
    }

    getUsers({
      pagination: {
        page: 0,
        rowsPerPage: 1,
      },
      filter: {
        id: userId,
      },
    });
  }, [userId]);

  const handleSubmit = (values: EditUserFormValues): void => {
    editUser(values);
  };

  const user = updatedUser || users?.users[0];

  return <EditTeamForm data={user} onSubmit={handleSubmit} isEdit={isEdit} />;
};

export default EditUser;
