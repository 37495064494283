import { Styled } from "./AuthLayout.styles";

type AuthLayoutProps = {
  children: React.ReactNode;
};

const AuthLayout: React.FC<AuthLayoutProps> = ({ children }) => (
  <Styled.Container>{children}</Styled.Container>
);

export default AuthLayout;
