import { Button, ButtonProps, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { useState } from 'react';

type ConfirmationButtonProps = ButtonProps & {
    dialogProps?: Partial<{ title: string; description: string; proceed: string; cancel: string }>;
    onClick?: () => void;
    onCancel?: () => void;
};

const ConfirmationButton: React.FC<ConfirmationButtonProps> = ({ dialogProps, onClick, onCancel, ...buttonProps }) => {
    const { title, description, proceed, cancel } = dialogProps || {};
    const [isDialogOpen, setDialogOpen] = useState(false);

    const handleClick: ButtonProps['onClick'] = () => {
        setDialogOpen(true);
    };

    const handleClose = (): void => {
        setDialogOpen(false);
    };

    const handleAccept = (): void => {
        onClick?.();
        handleClose();
    };

    const handleCancel = (): void => {
        onCancel?.();
        handleClose();
    };

    return (
        <>
            <Button {...buttonProps} onClick={handleClick} />

            <Dialog open={isDialogOpen} onClose={handleClose}>
                <DialogTitle>{title || 'Are you sure you want to perform this action?'}</DialogTitle>

                {description && (
                    <DialogContent dividers>
                        <Typography gutterBottom>{description}</Typography>
                    </DialogContent>
                )}

                <DialogActions
                    sx={{
                        pt: description ? 2 : 0,
                        pb: 2,
                        justifyContent: 'center',
                    }}>
                    <Button type="button" variant="contained" color="primary" autoFocus onClick={handleCancel}>
                        {cancel ? cancel : 'Cancel'}
                    </Button>

                    <Button type="button" color="inherit" onClick={handleAccept}>
                        {proceed ? proceed : 'Proceed'}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ConfirmationButton;
