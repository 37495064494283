import * as Yup from "yup";

const ERROR = {
  REQUIRED: "Required",
};

export const validationSchema = Yup.object().shape({
  name: Yup.string().required(ERROR.REQUIRED),
  description: Yup.string().required(ERROR.REQUIRED),
  city: Yup.string().required(ERROR.REQUIRED),
});
