import { EducationResourceType } from "@apps/sdk";
import * as Yup from "yup";

const ERROR = {
  REQUIRED: "Required",
  FILE_REQUIRED: "Please, select a file for upload.",
  INVALID_URL: "Please use only valid URLs",
};

export const validationSchema = Yup.object().shape({
  name: Yup.string().required(ERROR.REQUIRED),
  slug: Yup.string().required(ERROR.REQUIRED),
  type: Yup.string().required("Please select a type"),
  description: Yup.string().required(ERROR.REQUIRED),
  videoLink: Yup.string().when("type", ([type], schema) => {
    return type === EducationResourceType.Video
      ? schema.required(ERROR.REQUIRED).url(ERROR.INVALID_URL)
      : schema.notRequired();
  }),
});
