import { createTheme, Theme } from "@mui/material/styles";
import { DefaultTheme } from "@mui/styles";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#3065ca",
    },
  },
});

export type ThemeProps = { theme: DefaultTheme };
