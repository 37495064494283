import { useLocation, useMatch } from "react-router-dom";
import { ROUTES } from "../../routes";

import { Styled } from "./Breadcrumbs.styles";

const Breadcrumbs: React.FC = () => {
  const location = useLocation();
  const match = useMatch(location.pathname);

  const breadcrumbs = ROUTES.filter(({ pathname }) =>
    match?.pathname.includes(pathname)
  ).map(({ pathname, ...rest }) => ({
    pathname: Object.keys(match?.params || {}).length
      ? Object.keys(match?.params || {}).reduce(
          (path, param) =>
            path.replace(`:${param}`, match?.params[param] || ""),
          pathname
        )
      : pathname,
    ...rest,
  }));

  if (breadcrumbs.length <= 1) {
    return null;
  }

  return (
    <Styled.Breadcrumbs aria-label="breadcrumb">
      {breadcrumbs.map(({ name, pathname, icon: Icon, isDisabled }, index) => {
        if (
          breadcrumbs.length - 1 === index ||
          breadcrumbs.length === 1 ||
          !pathname ||
          isDisabled
        ) {
          return (
            <Styled.Typography key={name} color="textPrimary">
              {/* {Icon && <Icon />} */}
              {name}
            </Styled.Typography>
          );
        }

        return (
          <Styled.Link key={name} to={pathname}>
            {Icon && <Icon />}
            {index !== 0 && name}
          </Styled.Link>
        );
      })}
    </Styled.Breadcrumbs>
  );
};

export default Breadcrumbs;
