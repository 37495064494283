import Avatar from "@mui/material/Avatar";
import Typography, { TypographyProps } from "@mui/material/Typography";
import styled, { css } from "styled-components";

import bgImg from "./bg.jpg";

interface ImageProps {
  readonly src?: string;
  readonly alt?: string;
  readonly hasError?: boolean;
  readonly showPlaceholder?: boolean;
}

const ImageContainer = styled.div<{
  hasError?: boolean;
  showPlaceholder?: boolean;
}>`
  margin: 20px 8px 0 0;
  display: flex;
  align-items: center;

  ${({ showPlaceholder }) =>
    !showPlaceholder &&
    css`
      min-height: 55px;
    `}

  ${({ hasError }) => hasError && css``}
`;

const Image = styled.img`
    max-height: 250px;
    width: 100%;
    max-width: 200px;
    background-image: ${(props) => (props.src ? `url(${bgImg})` : undefined)};
    background-size: contain;
    border-radius: 3px;
`;

const UploadedImage: React.FC<ImageProps> = ({
  src,
  alt,
  hasError,
  showPlaceholder,
}) => (
  <ImageContainer hasError={hasError} showPlaceholder={showPlaceholder}>
    <Image src={src} alt={alt} />
  </ImageContainer>
);

const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 80%;
`;

const FileInput = styled.input`
  display: none;
`;

const Error: React.FC<TypographyProps> = (props) => (
  <Typography variant="caption" color="error" {...props} />
);

const Placeholder = styled(Avatar)`
  ${({ theme }) => css`
    width: 120px;
    height: 120px;

    padding: 32px;

    border: 1px solid ${theme.palette.grey[300]};
    background-color: ${theme.palette.grey[100]};
    border-radius: 8px;

    img {
      width: 100%;
    }
  `}
`;

export const Styled = {
  UploadedImage,
  TextContainer,
  FileInput,
  Error,
  Placeholder,
};
