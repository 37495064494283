import { FieldInputProps, FieldMetaProps, useField, useFormikContext } from 'formik';
import { PropsWithChildren } from 'react';

import { TextFieldProps } from '../components/TextField/TextField.types';

type UseTextFieldProps = PropsWithChildren<TextFieldProps> &
    Readonly<{
        name?: string;
        skipIsSubmittedCheck?: boolean;
    }>;

type UseTextField = <T = any>(
    props: UseTextFieldProps
) => PropsWithChildren<TextFieldProps> &
    Pick<FieldInputProps<T>, 'onChange' | 'onBlur'> &
    Pick<FieldMetaProps<T>, 'value'> & {
        helperText: any;
        error: boolean;
    };

export const useTextField: UseTextField = (props) => {
    if (!props.name) {
        throw Error('Field name must be specified!');
    }

    const { skipIsSubmittedCheck, ...rest } = props;

    const [field, meta] = useField(props.name);
    const { onChange, onBlur } = field;
    const { error, touched, value } = meta;

    const { submitCount } = useFormikContext<any>();
    const isFormSubmitted = !!submitCount;

    const hasError = !!error && (touched || (isFormSubmitted && !skipIsSubmittedCheck));
    const helperText = hasError && error;

    return {
        ...rest,
        value,
        onBlur: props.onBlur || onBlur,
        onChange: props.onChange || onChange,
        helperText,
        error: hasError,
    };
};
