import { Stars } from "@mui/icons-material";
import { Grid } from "@mui/material";

import AvatarUpload from "../../components/ImageUpload/AvatarUpload";
import { ImageUploadComponentProps } from "../../components/ImageUpload/withImageUpload";
import TextField from "../../components/TextField";

import { Styled } from "./EditFormHeader.styles";

type EditFormHeaderProps = Readonly<{
  name: string;
  role?: string;
  photoType: string;
  imgFileFieldName?: ImageUploadComponentProps["imgFileFieldName"];
  imgSourceFieldName?: ImageUploadComponentProps["imgSourceFieldName"];
}>;

const EditFormHeader: React.FC<EditFormHeaderProps> = ({
  name,
  role,
  photoType,
  imgSourceFieldName,
  imgFileFieldName,
}) => (
  <Grid container spacing={3}>
    <Grid item container xs={6} spacing={2} wrap="nowrap">
      <Grid item>
        {imgFileFieldName && imgSourceFieldName && (
          <AvatarUpload
            imgSourceFieldName={imgSourceFieldName}
            imgFileFieldName={imgFileFieldName}
            imgAltText={name}
            circularCrop
            noProcess={true}
          />
        )}
      </Grid>

      <Grid item>
        <Styled.NameContainer>
          <Styled.Name>{name || "Name"}</Styled.Name>
          <Styled.Description>
            <Stars />
            {role}
          </Styled.Description>
        </Styled.NameContainer>
      </Grid>
    </Grid>

    <Grid item xs={6}>
      <TextField
        name="description"
        label="Description"
        multiline
        InputProps={{
          style: {
            alignItems: "flex-start",
            height: "100%",
          },
        }}
        style={{
          height: "100%",
        }}
      />
    </Grid>
  </Grid>
);

export default EditFormHeader;
