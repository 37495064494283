import EditBlogPostForm, { DEFAULT_VALUES } from "./EditBlogPostForm";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useCreateOrEditBlogsQuery, useGetBlogsQuery } from "../../query-hooks";
import { CreateOrEditBlogRequest } from "@apps/sdk";

import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { PATH } from "../../routes";

const CreateOrEditBlogs: React.FC = () => {
  const { postId } = useParams<{ postId: string }>();
  const isEdit = !!postId;

  const { getBlogs, data: blogs } = useGetBlogsQuery();

  const { createOrEditBlogs, data: updatedBlogs } = useCreateOrEditBlogsQuery();

  const navigate = useNavigate();

  useEffect(() => {
    if (!postId) {
      return;
    }

    getBlogs({
      pagination: {
        page: 0,
        rowsPerPage: 1,
      },
      filter: {
        id: postId,
      },
    });
  }, [postId]);

  useEffect(() => {
    if (updatedBlogs?.id && !isEdit) {
      navigate(`${PATH.BLOG_POSTS}/edit/${updatedBlogs.id}`);
    }
  }, [updatedBlogs]);

  const handleSubmit = (values: CreateOrEditBlogRequest): void => {
    createOrEditBlogs(values);
  };

  const data = updatedBlogs || blogs?.blogs[0] || { ...DEFAULT_VALUES };

  return (
    <EditBlogPostForm data={data as any} onSubmit={handleSubmit} isEdit={isEdit} />
  );
};

export default CreateOrEditBlogs;
