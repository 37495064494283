import { Query, GetCountriesRequest, GetCountriesResponse } from "@apps/sdk";
import { useCallback } from "react";
import { useQueryState } from "./common";
import { frontofficeService } from "../services";

export const useGetCountriesQuery = () => {
    const { data, error, loading, setState, setLoading } = useQueryState<GetCountriesResponse>();

    const getCountries = useCallback(async (payload: GetCountriesRequest) => {
        setLoading(true);

        setState(await frontofficeService.query({
            query: Query.GetCountries,
            payload,
        }));

        setLoading(false);
    }, [])

    return {
        getCountries,
        data,
        error,
        loading
    }
}
