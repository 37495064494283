import {
  CreateOrEditEducationResourceRequest,
  CreateOrEditEducationResourceResponse,
  Query,
} from "@apps/sdk";
import { useCallback } from "react";
import { useQueryState } from "./common";
import { frontofficeService } from "../services";
import { toast } from "react-toastify";

export const useCreateOrEditEducationResourceQuery = () => {
  const { data, error, loading, setState, setLoading } =
    useQueryState<CreateOrEditEducationResourceResponse>();

  const createOrEditEducationResource = useCallback(
    async (payload: CreateOrEditEducationResourceRequest) => {
      setLoading(true);

      setState(
        await frontofficeService.query({
          query: Query.CreateOrEditEducationResource,
          payload: payload,
          onSuccess: (response: CreateOrEditEducationResourceResponse) => {
            toast.success("Educational Resource updated", {
              position: "top-right",
            });
          },
          onError: (errorResponse: any) => {
            errorResponse.errors.forEach(
              (error: any) => toast.error(error.message),
              {
                position: "top-right",
              }
            );
          },
        })
      );

      setLoading(false);
    },
    []
  );

  return {
    createOrEditEducationResource,
    data,
    error,
    loading,
  };
};
