import App from "./App";

import { Navigate, createBrowserRouter } from "react-router-dom";
import Users from "./pages/Users";
import EditUser from "./pages/EditUser";
import BlogPosts from "./pages/BlogPosts";
import Events from "./pages/Events";
import EditEvent from "./pages/EditEvent";
import EditBlogPost from "./pages/EditBlogPost";
import Pages from "./pages/Pages";
import EditPage from "./pages/EditPage";
import EducationalResources from "./pages/EducationalResources";
import EditEducationalResource from "./pages/EditEducationalResource";

export const path = {
  home: "/",
  login: "login",
  users: "users",
  events: "events",
  blogPosts: "blog-posts",
  pages: "pages",
  educationalResources: "educational-resources",
};

export const router = createBrowserRouter([
  {
    element: <App />,
    path: path.home,
    // TODO: errorElement: ''
    children: [
      {
        path: path.login,
        element: <Navigate to={path.home} />,
      },
      {
        path: path.users,
        element: <Users />,
      },
      {
        path: `${path.users}/edit/:userId`,
        element: <EditUser />,
      },
      {
        path: path.events,
        element: <Events />,
      },
      {
        path: `${path.events}/edit/:eventId`,
        element: <EditEvent />,
      },
      {
        path: path.blogPosts,
        element: <BlogPosts />,
      },
      {
        path: `${path.blogPosts}/edit/:postId`,
        element: <EditBlogPost />,
      },
      {
        path: `${path.blogPosts}/create`,
        element: <EditBlogPost />,
      },
      {
        path: path.pages,
        element: <Pages />,
      },
      {
        path: `${path.pages}/edit/:pageId`,
        element: <EditPage />,
      },
      {
        path: `${path.pages}/create`,
        element: <EditPage />,
      },
      {
        path: path.educationalResources,
        element: <EducationalResources />,
      },
      {
        path: `${path.educationalResources}/edit/:resourceId`,
        element: <EditEducationalResource />,
      },
      {
        path: `${path.educationalResources}/create`,
        element: <EditEducationalResource />,
      },
    ],
  },
], { basename: process.env.REACT_APP_PUBLIC_URL });
