import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

export enum ToastMessageType {
  ERROR = "ERROR",
  SUCCESS = "SUCCESS",
  INFO = "INFO",
  WARRNING = "WARRNING",
  DEFAULT = "DEFAULT",
  DARK = "DARK",
}

export const addToast = (message: string, type: ToastMessageType): void => {
  switch (type) {
    case ToastMessageType.ERROR:
      toast.error(message);
      break;
    case ToastMessageType.SUCCESS:
      toast.success(message);
      break;
    case ToastMessageType.INFO:
      toast.info(message);
      break;
    case ToastMessageType.WARRNING:
      toast.warning(message);
      break;
    case ToastMessageType.DEFAULT:
      toast(message);
      break;
    case ToastMessageType.DARK:
      toast.dark(message);
      break;
  }
};

export const addLoadingToast = (id?: string | number): string | number => {
  if (!id) {
    return toast.loading("Please wait...");
  }

  toast.update(id, {
    render: "Notification send",
    type: "success",
    isLoading: false,
  });
  return "";
};

export { ToastContainer };
