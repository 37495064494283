import { createGlobalStyle, CSSProp, css } from "styled-components";
import { normalize } from "styled-normalize";
import { ThemeProps } from "../theme";

export const GlobalStyle = createGlobalStyle`
    ${normalize}

    body {
        ${({ theme }: ThemeProps): CSSProp =>
          css`
            font-family: "Noto Sans", Tahoma, Arial, sans-serif;
            font-size: 1em;
          `}
    }
`;
