import { ExitToApp } from "@mui/icons-material";
import { Toolbar, Box, IconButton, Typography } from "@mui/material";
import logo from "../../assets/img/logo.svg";

import { Styled } from "./BaseLayout.styles";
import Sidebar from "../BaseLayout/Sidebar";
import { ReactNode } from "react";
import Breadcrumbs from "../../components/Breadcrumbs";
import { userState } from "../../state";
import { useLogout } from "../../hooks";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

const BaseLayout: React.FC<{ children?: ReactNode | undefined }> = ({
  children,
}) => {
  const handleLogout = useLogout();

  const user = userState.get();

  return (
    <Styled.Container>
      <Styled.AppBar elevation={0}>
        <Toolbar>
          <img src={logo} alt="SPERO" width="150" />

          <Box sx={{ flexGrow: 1 }} />

          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography sx={{ marginRight: 1 }}>
              {user?.fullName || ""}
            </Typography>

            <IconButton size="medium" color="inherit" onClick={handleLogout}>
              <ExitToApp />
            </IconButton>
          </Box>
        </Toolbar>
      </Styled.AppBar>

      <Styled.Drawer variant="permanent">
        <Toolbar />

        <Styled.DrawerContainer>
          <Sidebar />
        </Styled.DrawerContainer>
      </Styled.Drawer>

      <Styled.Main>
        <Toolbar />

        <Styled.MainContainer>
          <Breadcrumbs />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            {children}
          </LocalizationProvider>
        </Styled.MainContainer>
      </Styled.Main>
    </Styled.Container>
  );
};

export default BaseLayout;
