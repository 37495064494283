const size = {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '425px',
    tablet: '768px',
    laptop: '1024px',
    laptopL: '1440px',
    desktop: '2560px',
};

export const device = {
    mobileS: `(max-width: ${size.mobileS})`,
    mobileM: `(max-width: ${size.mobileM})`,
    mobileL: `(max-width: ${size.mobileL})`,
    tablet: `(max-width: ${size.tablet})`,
    laptop: `(max-width: ${size.laptop})`,
    laptopL: `(max-width: ${size.laptopL})`,
    desktop: `(max-width: ${size.desktop})`,
    desktopL: `(max-width: ${size.desktop})`,
};

// Breakpoints
export const smallestDeviceWidth = 320;
export const tabletWidth = 768;
export const desktopWidth = 1025;

export const breakpoint = {
    smallestDeviceMax: `(max-width: ${smallestDeviceWidth}px)`,
    tablet: `(min-width: ${tabletWidth}px)`,
    tabletMax: `(max-width: ${tabletWidth - 1}px)`,
    desktop: `(min-width: ${desktopWidth}px)`,
    desktopMax: `(max-width: ${desktopWidth - 1}px)`,
};
