export const PATH = {
  ROOT: "/",
  LOGIN: "/login",
  REGISTER: "/register",
  USERS: "/users",
  EDIT_USER: "/users/edit/:userId",
  BLOG_POSTS: "/blog-posts",
  CREATE_BLOG_POST: "/blog-posts/create",
  EDIT_BLOG_POST: "/blog-posts/edit/:postId",
  EVENTS: "/events",
  EDIT_EVENT: "/events/edit/:eventId",
  PAGES: "/pages",
  EDIT_PAGE: "/pages/edit/:pageId",
  CREATE_PAGE: "/pages/create",
  EDUCATIONAL_RESOURCES: "/educational-resources",
  CREATE_EDUCATIONAL_RESOURCE: "/educational-resources/create",
  EDIT_EDUCATIONAL_RESOURCE: "/educational-resources/edit/:resourceId",
};
