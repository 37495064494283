import { Button, Card, Grid, Typography } from "@mui/material";
import logo from "../../assets/logo_color.svg";
import React, { ChangeEvent, useCallback, useState } from "react";

import TextField from "../../components/TextField";

import { Styled } from "./Login.styles";
import {
  LoginRequest
} from "@apps/sdk";
import { useLoginQuery } from "../../query-hooks";

const Login: React.FC = () => {
  const [userInput, setUserInput] = useState<LoginRequest>({
    email: "",
    password: "",
  });

  const { login, data, error, loading } = useLoginQuery();

  const handleSubmit = useCallback(async () => await login({
    email: userInput.email,
    password: userInput.password
  }), [login, userInput]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const email = event.target.name;
    const value = event.target.value;

    setUserInput({
      ...userInput,
      [email]: value,
    });
  };

  return (
    <Styled.Wrapper>
      <img src={logo} alt="SPERO" width={250} />

      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <Card>
            <Styled.CardWrapper>
              <Typography variant="h6" component="h1" align="center">
                Admin Panel
              </Typography>

              <TextField
                isDefault
                name="email"
                label="Username"
                type="email"
                value={userInput.email}
                size="small"
                onChange={handleInputChange}
              />

              <TextField
                isDefault
                name="password"
                label="Password"
                type="password"
                value={userInput.password}
                size="small"
                onChange={handleInputChange}
              />

              <Button
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
                onClick={handleSubmit}
                disabled={!userInput.email || !userInput.password}
              >
                Log In
              </Button>
            </Styled.CardWrapper>
          </Card>
        </Grid>
      </Grid>
    </Styled.Wrapper>
  );
};

export default Login;
