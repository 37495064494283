import { ListItemIcon as MuiListItemIcon } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';

const ListItemIcon = styled(MuiListItemIcon)`
    min-width: 40px;
`;

const Link = styled(RouterLink)`
    display: block;
    width: 100%;
    color: inherit;
    text-decoration: none;
`;

export const Styled = {
    ListItemIcon,
    Link,
};
