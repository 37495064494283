import EditEventForm from "./EditEventForm";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useEditSportEventQuery } from "../../query-hooks";
import { useGetSportEventsQuery } from "../../query-hooks/useGetSportEventsQuery";
import {
  EditSportEventRequest,
  GetSportEventsResponse,
  SportEventCosts,
  SportEventStatus,
  UserStatus,
  UserType,
} from "@apps/sdk";

const EditUser: React.FC = () => {
  const { eventId } = useParams<{ eventId: string }>();
  const isEdit = !!eventId;

  const { getSportEvents, data: events } = useGetSportEventsQuery();

  const { editSportEvent, data: updatedSportEvent } = useEditSportEventQuery();

  useEffect(() => {
    if (!eventId) {
      return;
    }

    getSportEvents({
      pagination: {
        page: 0,
        rowsPerPage: 1,
      },
      filter: {
        id: eventId,
      },
    });
  }, [eventId]);

  const handleSubmit = (values: EditSportEventRequest): void => {
    const payload = {
      ...values,
      status: values.status.toLocaleLowerCase() as SportEventStatus,
      dateFrom: new Date(values.dateFrom).getTime().toString(),
      dateTo: new Date(values.dateTo).getTime().toString(),
    };

    editSportEvent(payload);
  };

  const DEFAULT_CREATED_BY = {
    id: "",
    email: "",
    fullName: "",
    type: UserType.SportOrganization,
    phoneNumber: "",
    description: "",
    socials: {
      links: [],
    },
    photoUrl: "",
    status: UserStatus.Approved,
  };

  const DEFAULTS: GetSportEventsResponse["sportEvents"][0] = {
    id: "",
    name: "",
    status: SportEventStatus.Draft,
    dateFrom: "",
    dateTo: "",
    city: "",
    costs: SportEventCosts.NotCovered,
    country: { id: "", name: "" },
    description: "",
    volunteersCount: 0,
    workingHoursCount: 0,
    approvedVolunteersCount: 0,
    keyResponsibilities: { list: [] },
    otherRequirements: { list: [] },
    createdBy: events?.sportEvents[0].createdBy || DEFAULT_CREATED_BY,
  };

  const event = updatedSportEvent || events?.sportEvents[0] || DEFAULTS;

  return <EditEventForm data={event} onSubmit={handleSubmit} isEdit={isEdit} />;
};

export default EditUser;
