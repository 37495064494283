import { Option } from "../../components/TextField";

import { convertToTitleCase } from "../string/convertToTitleCase";

type MapEnumsToOptions = <T extends Record<string, string>>(
  enums: T
) => Array<Option>;

export const mapEnumsToOptions: MapEnumsToOptions = (enums) =>
  Object.values(enums).map(
    (enumValue: string): Option => ({
      name: enumValue,
      value: enumValue,
    })
  );

type MapEnumsToOptionsToTitleCase = <T extends Record<string, string>>(
  enums: T,
  isSorted?: boolean
) => Array<Option>;

/** Converts an enum to an array of objects which can be used in a select field */
export const mapEnumsToOptionsToTitleCase: MapEnumsToOptionsToTitleCase = (
  enums,
  isSorted
) => {
  const values = isSorted
    ? [...Object.values(enums)].sort()
    : Object.values(enums);

  return values.map(
    (enumValue: string): Option => ({
      name: convertToTitleCase(enumValue),
      value: enumValue,
    })
  );
};

type MapArrayToOptions = (array: string[] | number[]) => Array<Option>;

export const mapArrayToOptions: MapArrayToOptions = (array) =>
  array.map((item: string | number): Option => {
    if (typeof item === "number") {
      return {
        name: item.toString(),
        value: item,
      };
    }

    return {
      name: item,
      value: item,
    };
  });
