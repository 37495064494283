import styled from "styled-components";
import { ThemeProps } from "../../theme";

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    position: relative;
    background-color: rgb(245, 245, 245)};
`;

export const Styled = {
  Container,
};
