import { Query, LoginRequest, LoginResponse } from "@apps/sdk";
import { useCallback, useEffect } from "react";
import { userState, tokenDataState } from "../state";
import { useQueryState } from "./common";
import { frontofficeService } from "../services";
import { toast } from "react-toastify";

export const useLoginQuery = () => {
  const { data, error, loading, setState, setLoading } =
    useQueryState<LoginResponse>();

  const login = useCallback(async (payload: LoginRequest) => {
    setLoading(true);

    setState(
      await frontofficeService.query({
        query: Query.Login,
        payload,
        onSuccess: (response) => {
          toast.success(`Welcome ${response.user.fullName}`, {
            position: "top-right",
          });
        },
        onError: (errorResponse) => {
          errorResponse.errors.forEach((error) => toast.error(error.message), {
            position: "top-right",
          });
        },
      })
    );

    setLoading(false);
  }, []);

  useEffect(() => {
    if (!data) {
      return;
    }

    tokenDataState.set({
      token: data.token,
      expireDate: data.expireDate,
    });

    userState.set(data.user);
  }, [data]);

  return {
    login,
    data,
    error,
    loading,
  };
};
