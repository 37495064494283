import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import React, { useState } from 'react';

import { StatusButtonProps } from './StatusButton.types';

const StatusButton: React.FC<StatusButtonProps> = ({ selected, options, onChange, disabled }) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.SyntheticEvent) => {
        setAnchorEl(event.currentTarget as HTMLButtonElement);
    };

    const handleClose = (): void => {
        setAnchorEl(null);
    };

    const handleItemClick = (value: string) => (): void => {
        onChange(value);
        handleClose();
    };

    return (
        <div>
            <Button
                variant="outlined"
                disableElevation
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon />}
                disabled={disabled}>
                {selected}
            </Button>

            <Menu
                elevation={2}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}>
                {options.map(
                    (option) =>
                        option !== selected && (
                            <MenuItem onClick={handleItemClick(option)} disableRipple key={option}>
                                {option}
                            </MenuItem>
                        )
                )}
            </Menu>
        </div>
    );
};

export default StatusButton;
