import { DataGrid, DataGridProps, GridToolbar } from "@mui/x-data-grid";
import { useState } from "react";

const tableOptions = {
  autoHeight: true,
  rowsPerPageOptions: [5, 10],
};

type DataTableProps = Omit<DataGridProps, "onPageChange"> & {
  onPageChange: (page: number, rowsPerPage: number) => void;
  hasActionButtons?: boolean;
  hasCheckboxes?: boolean;
  toolbar?: boolean;
  page: number;
  pageSize: number;
};

const DataTable = ({
  page,
  pageSize,
  onPageChange,
  rows,
  columns,
  loading,
  rowCount,
  toolbar,
}: DataTableProps) => {
  const [currentPage, setCurrentPage] = useState(page || 0);
  const [currentPageSize, setCurrentPageSize] = useState(pageSize || 0);

  const handlePageChange = (newPage: number): void => {
    setCurrentPage(newPage);
    onPageChange(newPage, currentPageSize);
  };

  const handlePageSizeChange = (newPageSize: number) => {
    setCurrentPageSize(newPageSize);
    onPageChange(currentPage, newPageSize);
  };

  return (
    <div style={{ height: "100%" }}>
      <DataGrid
        {...tableOptions}
        rows={rows}
        columns={columns}
        loading={loading}
        rowCount={rowCount}
        paginationMode="server"
        disableColumnSelector
        density="comfortable"
        checkboxSelection
        initialState={{
          pagination: { paginationModel: { pageSize: 5 } },
        }}
        pageSizeOptions={[5, 10, 25, 50]}
        onPaginationModelChange={(e) => {
          handlePageSizeChange(e.pageSize);
          handlePageChange(e.page);
        }}
        components={{
          Toolbar: toolbar ? GridToolbar : null,
        }}
      />
    </div>
  );
};

export default DataTable;
