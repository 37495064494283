"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ErrorCode = void 0;
var ErrorCode;
(function (ErrorCode) {
    ErrorCode["NOT_AUTHORIZED"] = "NOT_AUTHORIZED";
    ErrorCode["FILED_REQUIRED"] = "FILED_REQUIRED";
    ErrorCode["INVALID_PAYLOAD"] = "INVALID_PAYLOAD";
    ErrorCode["PAYLOAD_REQUIRED"] = "PAYLOAD_REQUIRED";
    ErrorCode["NO_PERMISSIONS"] = "NO_PERMISSIONS";
    ErrorCode["USER_EMAIL_ALREADY_EXIST"] = "USER_EMAIL_ALREADY_EXIST";
    ErrorCode["USER_NOT_FOUND"] = "USER_NOT_FOUND";
    ErrorCode["CANT_EDIT_USER"] = "CANT_EDIT_USER";
    ErrorCode["USER_NOT_APPROVED"] = "USER_NOT_APPROVED";
    ErrorCode["USER_INVALID_PASSWORD"] = "USER_INVALID_PASSWORD";
    ErrorCode["COUNTRY_NOT_FOUND"] = "COUNTRY_NOT_FOUND";
    ErrorCode["ALREADY_APPLIED_FOR_EVENT"] = "ALREADY_APPLIED_FOR_EVENT";
    ErrorCode["SPORT_EVENT_NOT_FOUND"] = "SPORT_EVENT_NOT_FOUND";
    ErrorCode["ONLY_VOLUNTEERS_CAN_APPLY"] = "ONLY_VOLUNTEERS_CAN_APPLY";
    ErrorCode["ONLY_ORGANIZATION_CAN_CREATE_EVENT"] = "ONLY_ORGANIZATION_CAN_CREATE_EVENT";
    ErrorCode["NO_PERMISSIONS_TO_EDIT_EVENT"] = "NO_PERMISSIONS_TO_EDIT_EVENT";
    ErrorCode["NO_PERMISSIONS_TO_VIEW_EVENT"] = "NO_PERMISSIONS_TO_VIEW_EVENT";
    ErrorCode["SPORT_EVENT_USER_NOT_FOUND"] = "SPORT_EVENT_USER_NOT_FOUND";
    ErrorCode["INVALID_RESOURCE_INPUT"] = "INVALID_RESOURCE_INPUT";
    ErrorCode["BLOG_NOT_FOUND"] = "BLOG_NOT_FOUND";
    ErrorCode["PAGE_NOT_FOUND"] = "PAGE_NOT_FOUND";
    ErrorCode["EDUCATION_RESOURCE_NOT_FOUND"] = "EDUCATION_RESOURCE_NOT_FOUND";
})(ErrorCode || (exports.ErrorCode = ErrorCode = {}));
