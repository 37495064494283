import * as Yup from "yup";

const ERROR = {
  REQUIRED: "Required",
};

export const validationSchema = Yup.object().shape({
  fullName: Yup.string().required(ERROR.REQUIRED),
  email: Yup.string().required(ERROR.REQUIRED),
});
