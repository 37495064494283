import { AppBar as MuiAppBar, Drawer as MuiDrawer } from "@mui/material";
import { NavLink as MuiNavLink } from "react-router-dom";
import styled from "styled-components";

const DRAWER_WIDTH = 240;

const Container = styled.div`
  display: flex;
  max-width: 1688px;
`;

const AppBar = styled(MuiAppBar)`
  z-index: 5000;

  img {
    height: 50px;
  }
`;

const Drawer = styled(MuiDrawer)`
  width: ${DRAWER_WIDTH}px;

  .MuiDrawer-paper {
    background-color: rgb(245, 245, 245);
  }
`;

const DrawerContainer = styled.div`
  width: ${DRAWER_WIDTH}px;
`;

const NavLink = styled(MuiNavLink)`
  text-decoration: none;
  color: #111;
  display: block;
`;

const Main = styled.main`
  flex-grow: 1;
`;

const MainContainer = styled.div`
  padding: 32px;
`;
export const Styled = {
  Container,
  AppBar,
  Drawer,
  DrawerContainer,
  NavLink,
  Main,
  MainContainer,
};
