export const parse = <T>(objString: string = ""): T | undefined => {
    if (!objString) {
        return;
    }

    try {
        const object = JSON.parse(objString) as { payload: T };

        return object as T;
    } catch (e) {
        return
    }
};

export const stringify = <T>(object: T): string => JSON.stringify({ payload: object });
