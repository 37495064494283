import { useNavigate } from "react-router-dom";
import { tokenDataState, userState } from "../state";

export const useLogout = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    tokenDataState.set(undefined);

    userState.set(undefined);

    navigate("/login");
  };

  return handleLogout;
};
