import { Checkbox as MuiCheckbox, TextField as MuiTextField } from '@mui/material';
import styled from 'styled-components';

const Checkbox = styled(MuiCheckbox)`
    padding-left: 0;

    &:hover,
    &.Mui-checked:hover {
        background: transparent;
    }
`;

const TextField = styled(MuiTextField)`
    .MuiFormLabel-root {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
    }
`;

export const Styled = { Checkbox, TextField };
