import { MenuItem } from '@mui/material';

import { Styled } from './DropdownSelect.styles';

interface DropdownSelectProps {
    data: any;
    id: string;
    label: string;
    name?: string;
    value?: string;
    disabled?: boolean;
    onChange?: any;
}

const DropdownSelect = ({ data, id, label, name, value, disabled, onChange }: DropdownSelectProps) => (
    <Styled.TextField
        id={id}
        name={name}
        select
        disabled={disabled}
        variant="outlined"
        size="small"
        type="text"
        label={label}
        onChange={onChange}
        value={value}>
        {data.map((option: any) => (
            <MenuItem key={option.id + option.name} value={option.id || option.value || ''}>
                {option.name}
            </MenuItem>
        ))}
    </Styled.TextField>
);
export default DropdownSelect;
