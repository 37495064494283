import { styled } from "@mui/material/styles";
import { ThemeProps } from "../../theme";

const Wrapper = styled("div")`
  max-width: 420px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  top: -65px;

  img {
    height: 100px;
    margin-bottom: 30px;
  }
`;

const CardWrapper = styled("div")`
  padding: 10px;

  & > *:not(:last-child) {
    margin-bottom: 10px;
  }
`;

export const Styled = {
  Wrapper,
  CardWrapper,
};
