import { Button, TextField, Grid, debounce } from "@mui/material";
import { GridCellParams, GridColDef } from "@mui/x-data-grid";
import { useFormik } from "formik";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import { PATH } from "../../routes";
import { getFileSrc } from "../../utils";

import Table from "../../components/Table";
import TableActions from "../../components/Table/TableActions";

import { Styled } from "./EducationalResources.styles";
import { EducationResourceType, PageStatus } from "@apps/sdk";
import StatusChip from "../../components/StatusChip";
import { useNavigate } from "react-router-dom";
import { useGetEducationalResourcesQuery } from "../../query-hooks";

function createData(
  id: string,
  name: string,
  type: EducationResourceType,
  status: PageStatus
) {
  return { id, name, type, status };
}

const EducationalResourcesTable: React.FC = () => {
  const navigate = useNavigate();

  const { getEducationalResources, data: resources } =
    useGetEducationalResourcesQuery();

  const [isSingleSearchInUse, setSingleSearchInUse] = useState(false);
  const [paginationCount, setPaginationCount] = useState(0);

  const [typeFilterValue, setTypeFilterValue] = useState({ type: "" });
  const [pageSize, setPageSize] = useState(10);

  const maxCount = resources?.maxCount || 0;

  useEffect(() => {
    getEducationalResources({
      pagination: {
        page: 0,
        rowsPerPage: pageSize,
      },
    });
  }, []);

  const filterForm = useFormik({
    initialValues: {
      type: "",
    },
    onSubmit: (values) => {
      setTypeFilterValue(values);
    },
  });

  useEffect(() => {
    if (maxCount !== 0) {
      setPaginationCount(maxCount);
    }
  }, [maxCount]);

  useEffect(() => {
    if (maxCount === 0 || typeFilterValue.type === "") {
      return;
    }

    setPaginationCount(resources?.resources.length || 0);
  }, [resources?.resources.length, maxCount, typeFilterValue]);

  const handlePageChange = useCallback((page: number, rowsPerPage: number) => {
    setPageSize(rowsPerPage);
    getEducationalResources({
      pagination: {
        page: page,
        rowsPerPage: rowsPerPage,
      },
    });
  }, []);

  const rows =
    resources &&
    resources.resources.map((row: any) => {
      return createData(row.id, row.name, row.type.toUpperCase(), row.status);
    });

  const renderTableActions = useCallback(
    (params: GridCellParams) => (
      <TableActions
        edit={{
          visible: true,
          page: PATH.EDUCATIONAL_RESOURCES,
          path: params.row.id,
        }}
      />
    ),
    []
  );

  const renderStatus = useCallback(
    (params: GridCellParams) => <StatusChip status={params.value} />,
    []
  );

  const renderAvatar = useCallback((params: GridCellParams) => {
    const avatarImg = params.row.imageUrl;

    const avatar = avatarImg ? (
      <>
        <Styled.ImgAvatar src={getFileSrc(avatarImg)} /> {` ${params.value}`}
      </>
    ) : (
      <>
        <Styled.Avatar>
          <>{params.value && params.value.toString().charAt(0).toUpperCase()}</>
        </Styled.Avatar>{" "}
        {` ${params.value}`}
      </>
    );
    return avatar;
  }, []);
  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      flex: 2,
      renderCell: renderAvatar,
    },
    {
      field: "type",
      headerName: "Resource Type",
      flex: 2,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: renderStatus,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "actions",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      headerName: "Actions",
      width: 130,
      renderCell: renderTableActions,
      align: "center",
      headerAlign: "center",
    },
  ];

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    if (value) {
      setPaginationCount(50);

      getEducationalResources({
        pagination: {
          page: 0,
          rowsPerPage: 100,
        },
        filter: {
          name: value,
        },
      });

      setSingleSearchInUse(true);

      return;
    }

    setPaginationCount(maxCount);
    setSingleSearchInUse(false);

    getEducationalResources({
      pagination: {
        page: 0,
        rowsPerPage: 10,
      },
    });
  };

  const handleCreatePage = () => navigate(PATH.CREATE_EDUCATIONAL_RESOURCE);

  const debouncedChangeHandler = useMemo(
    () => debounce(changeHandler, 300),
    []
  );

  if (!rows) {
    return null;
  }

  return (
    rows && (
      <Styled.Container>
        <Styled.FiltersContainer>
          <form onSubmit={filterForm.handleSubmit}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={4}>
                <TextField
                  name="search"
                  onChange={debouncedChangeHandler}
                  defaultValue=""
                  variant="outlined"
                  label="Search resource by title"
                  size="small"
                />
              </Grid>

              <Grid item xs={4}></Grid>

              <Grid
                container
                item
                xs={4}
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleCreatePage}
                >
                  Add New Resource
                </Button>
              </Grid>
            </Grid>
          </form>
        </Styled.FiltersContainer>

        <Table
          page={0}
          rows={rows}
          columns={columns}
          pageSize={pageSize}
          onPageChange={handlePageChange}
          rowCount={paginationCount}
          hasActionButtons={true}
        />
      </Styled.Container>
    )
  );
};

export default EducationalResourcesTable;
