import { Grid } from "@mui/material";
import { FormikProps, useFormikContext, withFormik } from "formik";
import { useEffect } from "react";
import { mapEnumsToOptionsToTitleCase } from "../../utils";

import ConfirmationButton from "../../components/ConfirmationButton";
import TextField from "../../components/TextField";
import EditFormHeader from "../../pages/EditFormHeader";

import { Styled } from "./EditUser.styles";
import { validationSchema } from "./EditUser.validation";
import { GetUsersResponse, UserStatus, UserType } from "@apps/sdk";
import { Upload } from "@packages/core-shared";
import { useGetCountriesQuery } from "../../query-hooks";

export type EditUserFormValues = {
  fullName: string;
  description?: string;
  email: string;
  type: UserType;
  country?: { name: string; id: string } | undefined;
  phoneNumber?: string;
  photo?: Upload | undefined;
  photoUrl?: string;
  status: UserStatus;
  logoFile?: File;
  website?: string;
  facebook?: string;
  xaccount?: string;
  linkedin?: string;
  youtube?: string;
};

const DEFAULT_VALUES: EditUserFormValues = {
  fullName: "",
  description: "",
  email: "",
  country: { id: "", name: "" },
  type: UserType.Volunteer,
  phoneNumber: "",
  photoUrl: "",
  status: UserStatus.Approved,
  website: "",
  facebook: "",
  xaccount: "",
  linkedin: "",
  youtube: "",
};

type EditUserProps = Readonly<{
  data:
    | (GetUsersResponse["users"][0] & { photo?: Upload | undefined })
    | undefined;
  onSubmit: (values: EditUserFormValues) => void;
  isEdit: boolean;
}>;
type EditUserFormProps = FormikProps<EditUserFormValues> & EditUserProps;

const EditUserForm: React.FC<EditUserFormProps> = (props) => {
  const {
    handleSubmit,
    values: {
      status,
      fullName,
      website,
      facebook,
      xaccount,
      linkedin,
      youtube,
      country,
      logoFile,
    },
    data,
    isEdit,
  } = props;
  const { setFieldValue, submitForm, initialValues } = useFormikContext();

  const { getCountries, data: countriesData } = useGetCountriesQuery();

  const statusOptions = mapEnumsToOptionsToTitleCase(UserStatus);

  useEffect(() => {
    getCountries({
      pagination: {
        page: 0,
        rowsPerPage: 100,
      },
    });
  }, []);

  const countryOptions = countriesData?.countries.map((country) => ({
    name: country.name,
    value: country.id,
  }));

  useEffect(() => {
    if (!data) {
      return;
    }

    setFieldValue("countryId", country?.id);

    data?.socials?.links.map((link) => {
      setFieldValue(link.name, link.url);
    });

    setFieldValue("status", data?.status);
  }, [initialValues]);

  const socialLinks = {
    links: [
      { name: "website", url: website },
      { name: "facebook", url: facebook },
      { name: "xaccount", url: xaccount },
      { name: "linkedin", url: linkedin },
      { name: "youtube", url: youtube },
    ],
  };

  useEffect(() => {
    const photoPayload = new Upload(logoFile);
    setFieldValue("photo", photoPayload);
  }, [logoFile, data]);

  useEffect(() => {
    setFieldValue("socials", socialLinks);
  }, [website, facebook, xaccount, linkedin, youtube]);

  const handleCountryChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const selectedCountryValue = event.target.value;
    const selectedCountry =
      countryOptions &&
      countryOptions.find((country) => country.value === selectedCountryValue);
    setFieldValue("country", {
      id: selectedCountry?.value,
      name: selectedCountry?.name,
    });
    setFieldValue("countryId", selectedCountry?.value);
  };

  const handleStatusChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFieldValue(
      "status",
      event.target.value === UserStatus.Approved
        ? UserStatus.Approved
        : UserStatus.Pending
    );
  };

  return (
    <Styled.Container>
      <form onSubmit={handleSubmit} noValidate>
        <EditFormHeader
          name={fullName}
          photoType="Profile"
          imgSourceFieldName="photoUrl"
          imgFileFieldName="logoFile"
          role={data?.type}
        />

        <Styled.Divider />

        <Grid container spacing={3} alignContent="center">
          <Grid item xs={6}>
            <TextField name="fullName" label="Full Name" required />
          </Grid>

          <Grid item xs={6}>
            <TextField name="email" label="Email" required />
          </Grid>

          <Grid item xs={6}>
            <TextField
              name="countryId"
              select
              label="Country"
              options={countryOptions}
              required
              onChange={(e) => handleCountryChange(e)}
            />
          </Grid>

          {/* <Grid item xs={6}>
            <TextField
              name="password"
              type="password"
              label="Password"
              autoComplete="new-password"
              required
            />
          </Grid> */}

          <Grid item xs={6}>
            <TextField name="phoneNumber" label="Phone Number" />
          </Grid>
        </Grid>

        <Styled.Divider />

        <Grid container spacing={3} alignContent="center">
          <Grid item xs={6}>
            <TextField name="website" label="Website" />
          </Grid>

          <Grid item xs={6}>
            <TextField name="facebook" label="Facebook" />
          </Grid>

          <Grid item xs={6}>
            <TextField name="xaccount" label="X account" />
          </Grid>

          <Grid item xs={6}>
            <TextField name="linkedin" label="LinkedIn" />
          </Grid>

          <Grid item xs={6}>
            <TextField name="youtube" label="YouTube" />
          </Grid>
        </Grid>

        <Styled.Divider />

        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={4}>
            {isEdit && data?.type === UserType.SportOrganization && (
              <TextField
                name="status"
                select
                label="Status"
                options={statusOptions}
                onChange={(e) => handleStatusChange(e)}
              />
            )}
          </Grid>

          <Grid item>
            <Styled.ButtonsContainer>
              <ConfirmationButton
                variant="contained"
                color="inherit"
                onClick={() => submitForm()}
                dialogProps={{
                  title:
                    "Are you sure you want to save the changes for this user?",
                  proceed: "yes",
                  cancel: "no",
                }}
              >
                Save Changes
              </ConfirmationButton>
            </Styled.ButtonsContainer>
          </Grid>
        </Grid>
      </form>
    </Styled.Container>
  );
};

const EditUserFormWrapper = withFormik<EditUserProps, EditUserFormValues>({
  mapPropsToValues: ({ data }) => {
    if (!data) {
      return DEFAULT_VALUES;
    }

    const {
      id,
      description,
      fullName,
      type,
      email,
      country,
      phoneNumber,
      socials,
      photoUrl,
      photo,
      status,
    } = data;

    return {
      ...DEFAULT_VALUES,
      id,
      fullName,
      description: description || DEFAULT_VALUES.description,
      country: country || DEFAULT_VALUES.country,
      email,
      type,
      phoneNumber: phoneNumber || DEFAULT_VALUES.phoneNumber,
      socials: socials || { links: [] },
      photo,
      status,
      photoUrl: photoUrl as string,
    };
  },
  validationSchema,
  enableReinitialize: true,
  handleSubmit: (values, { props: { onSubmit } }) => {
    const {
      website,
      facebook,
      xaccount,
      linkedin,
      youtube,
      photoUrl,
      logoFile,
      ...rest
    } = values;
    onSubmit(rest);
  },
  displayName: "EditUserForm",
})(EditUserForm);

export default EditUserFormWrapper;
