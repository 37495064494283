import {
  Archive,
  Check,
  Close,
  HelpOutline,
  Restore,
  Visibility,
  VisibilityOff,
  AppRegistration,
  EditCalendar,
} from "@mui/icons-material";
import ModeIcon from "@mui/icons-material/Mode";

import { ChipStatus, Styled } from "./StatusChip.styles";

const generateProps = (status: string) => {
  let chipProps;

  const lowerCaseStatus = status.toLocaleLowerCase();

  switch (lowerCaseStatus) {
    case "unknown":
      chipProps = {
        icon: <HelpOutline />,
        label: "Unknown",
      };
      break;
    case "active":
      chipProps = {
        icon: <Check />,
        label: "Active",
        status: ChipStatus.Success,
      };
      break;
    case "approved":
      chipProps = {
        icon: <Check />,
        label: "Approved",
        status: ChipStatus.Success,
      };
      break;
    case "approved_manually":
      chipProps = {
        icon: <Check />,
        label: "Approved Manually",
        status: ChipStatus.Success,
      };
      break;
    case "paid":
      chipProps = {
        icon: <Check />,
        label: "Paid",
        status: ChipStatus.Success,
      };
      break;
    case "joined":
      chipProps = {
        icon: <Restore />,
        label: "Joined",
        status: ChipStatus.Info,
      };
      break;
    case "pending":
      chipProps = {
        icon: <Restore />,
        label: "Pending",
        status: ChipStatus.Info,
      };
      break;
    case "hidden":
      chipProps = {
        icon: <VisibilityOff />,
        label: "Hidden",
      };
      break;
    case "published":
      chipProps = {
        icon: <EditCalendar />,
        label: "Published",
      };
      break;
    case "visible":
      chipProps = {
        icon: <Visibility />,
        label: "Visible",
      };
      break;
    case "open":
      chipProps = {
        icon: <Visibility />,
        label: "Open",
      };
      break;
    case "registration":
      chipProps = {
        icon: <AppRegistration />,
        label: "Registration",
      };
      break;
    case "archived":
      chipProps = {
        icon: <Archive />,
        label: "Archived",
      };
      break;
    case "rejected":
      chipProps = {
        icon: <Close />,
        label: "Rejected",
        status: ChipStatus.Reject,
      };
      break;
    case "manual":
      chipProps = {
        icon: <Check />,
        label: "Manual",
      };
      break;
    case "failed":
      chipProps = {
        icon: <Close />,
        label: "Failed",
        status: ChipStatus.Error,
      };
      break;
    case "denied":
      chipProps = {
        icon: <Close />,
        label: "Denied",
        status: ChipStatus.Error,
      };
      break;
    case "draft":
      chipProps = {
        icon: <ModeIcon />,
        label: "Draft",
        status: ChipStatus.Info,
      };
      break;
    default:
      chipProps = {
        icon: <Check />,
        label: "Active",
      };
  }

  return chipProps;
};

const StatusChip = ({ status }: any) => {
  const chipProps = generateProps(status?.toLowerCase());

  return (
    <Styled.Chip
      color="primary"
      variant="outlined"
      size="small"
      {...chipProps}
    />
  );
};

export default StatusChip;
