import { Avatar as MUIAvatar } from "@mui/material";
import styled from "styled-components";

const Container = styled.div`
  padding: 16px 0;

  .MuiFormControl-root,
  .MuiFormControl-root {
    width: 100%;
  }
`;

const Avatar = styled(MUIAvatar)`
  background-color: rgb(33, 70, 141);
  margin-right: 10px;
`;

const FiltersContainer = styled.div`
  margin-bottom: 20px;
`;

const EmailOption = styled.span`
  font-size: 11;
  color: #ddd;
`;

const ImgAvatar = styled(MUIAvatar)`
  margin-right: 10px;
`;

export const Styled = {
  Avatar,
  Container,
  ImgAvatar,
  FiltersContainer,
  EmailOption,
};
