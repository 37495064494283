import {
  Query,
  GetUsersRequest,
  GetUsersResponse
} from "@apps/sdk";
import { useCallback } from "react";
import { useQueryState } from "./common";
import { frontofficeService } from "../services";

export const useGetUsersQuery = () => {
  const { data, error, loading, setState, setLoading } =
    useQueryState<GetUsersResponse>();

  const getUsers = useCallback(async (payload: GetUsersRequest) => {
    setLoading(true);

    setState(await frontofficeService.query({
      query: Query.GetUsers,
      payload,
    }));

    setLoading(false);
  }, []);

  return {
    getUsers,
    data,
    error,
    loading,
  };
};
