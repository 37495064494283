import { User } from "@apps/sdk";
import { State } from "@packages/core-shared";
import { jsonUtils } from "../utils";

const STORAGE_KEY = 'user';

const persistedUserData = localStorage.getItem(STORAGE_KEY);

export const userState = new State<User | undefined>(persistedUserData ? jsonUtils.parse<User>(persistedUserData) : undefined);

userState.addChangeListener((_, newState) => {
    newState ? localStorage.setItem(STORAGE_KEY, JSON.stringify(newState)) : localStorage.removeItem(STORAGE_KEY)
})
