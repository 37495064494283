import EditPageForm, { DEFAULT_VALUES } from "./EditPageForm";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { CreateOrEditPageRequest } from "@apps/sdk";
import { useNavigate } from "react-router-dom";
import { PATH } from "../../routes";
import { useCreateOrEditPagesQuery } from "../../query-hooks/useCreateOrEditPagesQuery";
import { useGetPagesQuery } from "../../query-hooks/useGetPagesQuery";

const CreateOrEditPage: React.FC = () => {
  const { pageId } = useParams<{ pageId: string }>();
  const isEdit = !!pageId;

  const { getPages, data: pages } = useGetPagesQuery();

  const { createOrEditPage, data: updatedPage } = useCreateOrEditPagesQuery();

  const navigate = useNavigate();

  useEffect(() => {
    if (!pageId) {
      return;
    }

    getPages({
      pagination: {
        page: 0,
        rowsPerPage: 1,
      },
      filter: {
        id: pageId,
      },
    });
  }, [pageId]);

  useEffect(() => {
    if (updatedPage?.id && !isEdit) {
      navigate(`${PATH.PAGES}/edit/${updatedPage.id}`);
    }
  }, [updatedPage]);

  const handleSubmit = (values: CreateOrEditPageRequest): void => {
    createOrEditPage(values);
  };

  const data = updatedPage || pages?.pages[0] || { ...DEFAULT_VALUES };

  return <EditPageForm data={data} onSubmit={handleSubmit} isEdit={isEdit} />;
};

export default CreateOrEditPage;
