import { Grid } from "@mui/material";
import { FormikProps, useFormikContext, withFormik } from "formik";
import { ReactEventHandler, useEffect } from "react";
import { getFileSrc, mapEnumsToOptionsToTitleCase, slugify } from "../../utils";

import ConfirmationButton from "../../components/ConfirmationButton";
import TextField from "../../components/TextField";

import { Styled } from "./EditBlogPost.styles";
import { validationSchema } from "./EditBlogPost.validation";
import {
  BlogStatus,
  CreateOrEditBlogRequest,
  GetBlogsResponse,
} from "@apps/sdk";
import { Upload } from "@packages/core-shared";
import ImageUploader from "../../components/ImageUploader/ImageUploader";
import HTMLEditor from "../../components/HTMLEditor";

export const DEFAULT_VALUES = {
  id: "",
  title: "",
  text: "",
  slug: "",
  status: BlogStatus.Hidden,
  image: undefined,
};

type EditBlogProps = Readonly<{
  data: GetBlogsResponse["blogs"][0];
  onSubmit: (values: CreateOrEditBlogRequest) => void;
  isEdit: boolean;
}>;
type EditBlogsFormProps = FormikProps<CreateOrEditBlogRequest> & EditBlogProps;

const EditBlogsForm: React.FC<EditBlogsFormProps> = (props) => {
  const { handleSubmit, data, values, isEdit } = props;
  const { setFieldValue, submitForm, initialValues } = useFormikContext();

  const { title, text } = values;
  const statusOptions = mapEnumsToOptionsToTitleCase(BlogStatus);

  useEffect(() => {
    if (!data) {
      return;
    }

    setFieldValue("status", data?.status);
  }, [initialValues]);

  useEffect(() => {
    setFieldValue("slug", slugify(title));
  }, [title, setFieldValue]);

  const handleStatusChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFieldValue("status", event.target.value);
  };

  const handleHtmlChange = (newHtml: string): void => {
    setFieldValue("text", newHtml);
  };

  const handleImageUpload = (file: File) =>
    setFieldValue("image", new Upload(file));

  if (isEdit && data.id === "") {
    return null;
  }

  return (
    <Styled.Container>
      <form onSubmit={handleSubmit} noValidate>
        <Grid item xs={4}>
          <ImageUploader
            onImageUpload={handleImageUpload}
            buttonText="Select Image"
            initialImage={getFileSrc(data.imageUrl || "")}
          />
        </Grid>

        <Styled.Divider />
        <Grid container spacing={3} alignContent="center">
          <Grid item xs={6}>
            <TextField name="title" label="Title" required />
          </Grid>

          <Grid item xs={6}>
            <TextField name="slug" label="Slug" required />
          </Grid>

          <Grid item xs={12}>
            <HTMLEditor html={text} onChange={handleHtmlChange} />
          </Grid>
        </Grid>

        <Styled.Divider />

        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={4}>
            {isEdit && (
              <TextField
                name="status"
                select
                label="Status"
                options={statusOptions}
                onChange={(e) => handleStatusChange(e)}
              />
            )}
          </Grid>

          <Grid item>
            <Styled.ButtonsContainer>
              <ConfirmationButton
                variant="contained"
                color="inherit"
                onClick={() => submitForm()}
                dialogProps={{
                  title:
                    "Are you sure you want to save the changes for this post?",
                  proceed: "yes",
                  cancel: "no",
                }}
              >
                Save Changes
              </ConfirmationButton>
            </Styled.ButtonsContainer>
          </Grid>
        </Grid>
      </form>
    </Styled.Container>
  );
};

const EditBlogsFormWrapper = withFormik<EditBlogProps, CreateOrEditBlogRequest>(
  {
    mapPropsToValues: ({ data }) => {
      if (!data) {
        return DEFAULT_VALUES;
      }

      const { id, title, text, slug, status, imageUrl } = data;

      return {
        ...DEFAULT_VALUES,
        id,
        title,
        status,
        text,
        slug,
        imageUrl,
      };
    },
    validationSchema,
    enableReinitialize: true,
    handleSubmit: (values, { props: { onSubmit } }) => {
      onSubmit(values);
    },
    displayName: "EditBlogsForm",
  }
)(EditBlogsForm);

export default EditBlogsFormWrapper;
