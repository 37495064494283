import { State } from "@packages/core-shared";
import { tokenDataState } from ".";
import { Locale } from "../app-constants";
import { jsonUtils } from "../utils";
import enTranslationsDictionary from "../app-constants/locales/en.json";

export type LocaleTranslationDictionary = typeof enTranslationsDictionary;

export type LocaleTranslationState = {
  locale: string;
  translationsDictionary: LocaleTranslationDictionary;
};

const STORAGE_KEY = 'translations';

const persistedTranslations = localStorage.getItem(STORAGE_KEY);

export const translationsDictionaryState = new State<LocaleTranslationState>(jsonUtils.parse<LocaleTranslationState>(persistedTranslations || '') || {
    locale: Locale.EN,
    translationsDictionary: enTranslationsDictionary,
});

tokenDataState.addChangeListener((_, newState) => {
    newState ? localStorage.setItem(STORAGE_KEY, JSON.stringify(newState)) : localStorage.removeItem(STORAGE_KEY)
})
