import BaseLayout from "./layouts/BaseLayout";
import "./styles/global.scss";

import { StyledEngineProvider } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./theme";
import { GlobalStyle } from "./styles";

import { Outlet } from "react-router-dom";
import { AuthLayout } from "./layouts";
import Login from "./pages/Login";
import { useCheckIsLoggedIn } from "./hooks";
import { ToastContainer } from "./utils";

const App = () => {
  const { isLoggedIn, loading } = useCheckIsLoggedIn();

  if (loading) {
    return null
  }

  return (
    <ThemeProvider theme={theme}>
      <ToastContainer className={"toast-container"} />
      
      <GlobalStyle theme={theme} />
      <StyledEngineProvider injectFirst>
        {isLoggedIn && (
          <BaseLayout>
            <Outlet />
          </BaseLayout>
        )}
        {!isLoggedIn && (
          <AuthLayout>
            <Login />
          </AuthLayout>
        )}
      </StyledEngineProvider>
    </ThemeProvider>
  );
};

export default App;
