import { Typography } from "@mui/material";
import React, { useMemo } from "react";
import styled from "styled-components";

import StatusButton, { StatusButtonProps } from "../StatusButton";

interface StatusButtonWithTextProps extends StatusButtonProps {
  readonly type: string;
}

const Container = styled.div`
  display: flex;
  align-items: center;

  > *:first-child {
    margin-right: 10px;
  }
`;

const StatusButtonWithText: React.FC<StatusButtonWithTextProps> = ({
  type,
  ...props
}) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps

  return (
    <Container>
      <StatusButton {...props} />

      <Typography color={props.disabled ? "lightgrey" : "primary"}>
        The status of this {type} is {props.selected.toLowerCase()}
      </Typography>
    </Container>
  );
};

export default StatusButtonWithText;
