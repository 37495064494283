import React, { useState, ChangeEvent } from "react";
import { Button, Grid, Typography, Link } from "@mui/material";
import { toast } from "react-toastify";
import DownloadIcon from "@mui/icons-material/Download";

interface FileUploadProps {
  onFileUpload: (file: File) => void;
  acceptedTypes: string[];
  fileUrl?: string;
}

const FileUpload: React.FC<FileUploadProps> = ({
  onFileUpload,
  acceptedTypes,
  fileUrl,
}) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const isFileTypeValid = (file: File): boolean => {
    return acceptedTypes.includes(file.type);
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      if (isFileTypeValid(file)) {
        setSelectedFile(file);
        onFileUpload(file);
      } else {
        toast.error("Invalid file type. Please select a valid document type.");
      }
    }
  };

  const bytesToMB = (bytes: number) => {
    return (bytes / (1024 * 1024)).toFixed(2);
  };

  return (
    <Grid container spacing={3} alignContent="center">
      {!fileUrl?.includes('fallback') ? (
        <>
          <Grid item xs={6}>
            <Typography>
              <Link href={fileUrl} target="_blank" rel="noopener noreferrer">
                Download File <DownloadIcon />
              </Link>
            </Typography>
          </Grid>
          <Grid item xs={6} alignItems="center">
            <label htmlFor="fileInput">
              <Button variant="contained" component="span">
                Replace Document
              </Button>
            </label>
          </Grid>
        </>
      ) : (
        <Grid item xs={6} alignItems="center">
          <label htmlFor="fileInput">
            <Button variant="contained" component="span">
              Upload Document
            </Button>
          </label>
        </Grid>
      )}

      {selectedFile && (
        <Grid item xs={6}>
          <Typography>{`Name: ${selectedFile.name}`}</Typography>
          <Typography>{`Size: ${bytesToMB(selectedFile.size)} Mb`}</Typography>
          <Typography>{`Type: ${selectedFile.type}`}</Typography>
        </Grid>
      )}

      <input
        type="file"
        accept={acceptedTypes.join(",")}
        style={{ display: "none" }}
        id="fileInput"
        onChange={handleFileChange}
      />
    </Grid>
  );
};

export default FileUpload;
