import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import IconButton from '@mui/material/IconButton';
import { ReactElement, useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';

interface TableActionsProps {
    edit?: any;
    view?: any;
}

const TableActions = ({ edit, view }: TableActionsProps): ReactElement => {
    const handleEdit = (page: string, path: string) => `${page}/edit/${path}`;

    return useMemo(
        () => (
            <>
                {edit.visible && (
                    <RouterLink to={handleEdit(edit.page, edit.path)}>
                        <IconButton edge="start" size="large">
                            <EditIcon color="primary" />
                        </IconButton>
                    </RouterLink>
                )}
                {view?.visible && (
                    <IconButton edge="start" size="large">
                        <VisibilityIcon color="primary" />
                    </IconButton>
                )}
            </>
        ),
        [edit, view]
    );
};

export default TableActions;
