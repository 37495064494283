import * as Yup from "yup";

const ERROR = {
  REQUIRED: "Required",
};

export const validationSchema = Yup.object().shape({
  title: Yup.string().required(ERROR.REQUIRED),
  slug: Yup.string().required(ERROR.REQUIRED),
  text: Yup.string().required(ERROR.REQUIRED),
});
