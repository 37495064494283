import { JwtResponse } from "@apps/sdk";

const JWT_REGEX = /^[A-Za-z0-9-_=]+\.[A-Za-z0-9-_=]+\.?[A-Za-z0-9-_.+/=]*$/;

export const minutesToMilliseconds = (minutes: number) => minutes * 60 * 1000;

export const millisecondToMinutes = (milliseconds: number) => Math.round(milliseconds / 1000 / 60);

export const TOKEN_EXPIRE_TIME_MINUTES = 60;

export const shouldRefreshToken = (tokenState: JwtResponse | undefined): boolean => {    
    if (!tokenState?.expireDate) {
        return false;
    }

    const diff = new Date(tokenState.expireDate).valueOf() - Date.now();

    return millisecondToMinutes(diff) < Math.round(TOKEN_EXPIRE_TIME_MINUTES / 2);
};

export const isValidToken = (tokenState: JwtResponse | undefined): boolean => {
    if (!tokenState?.token || !tokenState?.expireDate) {
        return false;
    }

    if (!JWT_REGEX.test(tokenState.token)) {
        return false;
    }

    const expireDate = new Date(tokenState.expireDate);
    if (new Date().valueOf() > expireDate.valueOf()) {
        return false;
    }

    return true;
};
