import {
  Query,
  GetSportEventsRequest,
  GetSportEventsResponse,
} from "@apps/sdk";
import { useCallback } from "react";
import { useQueryState } from "./common";
import { frontofficeService } from "../services";

export const useGetSportEventsQuery = () => {
  const { data, error, loading, setState, setLoading } =
    useQueryState<GetSportEventsResponse>();

  const getSportEvents = useCallback(async (payload: GetSportEventsRequest) => {
    setLoading(true);

    setState(await frontofficeService.query({
      query: Query.GetSportEvents,
      payload,
    }));

    setLoading(false);
  }, []);

  return {
    getSportEvents,
    data,
    error,
    loading,
  };
};
