import { Grid, TextField, debounce } from "@mui/material";
import { GridCellParams, GridColDef } from "@mui/x-data-grid";
import { useFormik } from "formik";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import { PATH } from "../../routes";
import { getFileSrc } from "../../utils";

import Table from "../../components/Table";
import TableActions from "../../components/Table/TableActions";

import { Styled } from "./Events.styles";
import { SportEventStatus } from "@apps/sdk";
import StatusChip from "../../components/StatusChip";
import { useGetSportEventsQuery } from "../../query-hooks";
import { dateFromTimestamp } from "@packages/core-shared";

function createData(
  id: string,
  name: string,
  dateFrom: Date,
  dateTo: Date,
  country: { name: string; id: string },
  volunteersCount: number,
  approvedVolunteersCount: number,
  workingHoursCount: number,
  status: SportEventStatus
) {
  return {
    id,
    name,
    dateFrom,
    dateTo,
    country,
    volunteersCount,
    approvedVolunteersCount,
    workingHoursCount,
    status,
  };
}

const SportEventsTable: React.FC = () => {
  const { getSportEvents, data: events } = useGetSportEventsQuery();

  const [isSingleSearchInUse, setSingleSearchInUse] = useState(false);
  const [paginationCount, setPaginationCount] = useState(0);

  const [typeFilterValue, setTypeFilterValue] = useState({ type: "" });
  const [pageSize, setPageSize] = useState(10);

  const maxCount = events?.maxCount || 0;

  useEffect(() => {
    getSportEvents({
      pagination: {
        page: 0,
        rowsPerPage: pageSize,
      },
    });
  }, []);

  const filterForm = useFormik({
    initialValues: {
      type: "",
      filterName: "",
    },
    onSubmit: (values) => {
      setTypeFilterValue(values);
    },
  });

  // useEffect(() => {
  //   if (typeFilterValue.type === "") {
  //     return;
  //   }

  //   getUsers({
  //     pagination: {
  //       page: 0,
  //       rowsPerPage: pageSize,
  //     },
  //     filter: {
  //       type:
  //         typeFilterValue.type === UserType.Volunteer
  //           ? UserType.Volunteer
  //           : UserType.SportOrganization,
  //     },
  //   });

  //   console.log(typeFilterValue);
  // }, [typeFilterValue]);

  useEffect(() => {
    if (maxCount !== 0) {
      setPaginationCount(maxCount);
    }
  }, [maxCount]);

  useEffect(() => {
    if (maxCount === 0 || typeFilterValue.type === "") {
      return;
    }

    setPaginationCount(events?.sportEvents.length || 0);
  }, [events?.sportEvents.length, maxCount, typeFilterValue]);

  const handlePageChange = useCallback((page: number, rowsPerPage: number) => {
    setPageSize(rowsPerPage);
    getSportEvents({
      pagination: {
        page: page,
        rowsPerPage: rowsPerPage,
      },
    });
  }, []);

  const rows =
    events &&
    events.sportEvents.map((row: any) => {
      const dateFrom = dateFromTimestamp(row.dateFrom);
      const dateTo = dateFromTimestamp(row.dateTo);

      return createData(
        row.id,
        row.name,
        dateFrom,
        dateTo,
        row.country?.name || "N/A",
        row.volunteersCount,
        row.approvedVolunteersCount,
        row.workingHoursCount,
        row.status
      );
    });

  const renderTableActions = useCallback(
    (params: GridCellParams) => (
      <TableActions
        edit={{
          visible: true,
          page: PATH.EVENTS,
          path: params.row.id,
        }}
      />
    ),
    []
  );

  const renderStatus = useCallback(
    (params: GridCellParams) => <StatusChip status={params.value} />,
    []
  );

  const renderAvatar = useCallback((params: GridCellParams) => {
    const avatarImg = params.row.photoUrl;

    const avatar = avatarImg ? (
      <>
        <Styled.ImgAvatar src={getFileSrc(avatarImg)} /> {` ${params.value}`}
      </>
    ) : (
      <>
        <Styled.Avatar>
          <>{params.value && params.value.toString().charAt(0).toUpperCase()}</>
        </Styled.Avatar>{" "}
        {` ${params.value}`}
      </>
    );
    return avatar;
  }, []);
  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Title",
      flex: 2,
      renderCell: renderAvatar,
    },
    {
      field: "dateFrom",
      headerName: "From",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "dateTo",
      headerName: "To",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "country",
      headerName: "Country",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "volunteersCount",
      headerName: "Needed Volunteers",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "approvedVolunteersCount",
      headerName: "Approved Volunteers",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "workingHoursCount",
      headerName: "Working Hours",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: renderStatus,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "actions",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      headerName: "Actions",
      width: 130,
      renderCell: renderTableActions,
      align: "center",
      headerAlign: "center",
    },
  ];

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    if (value) {
      setPaginationCount(50);

      getSportEvents({
        pagination: {
          page: 0,
          rowsPerPage: 100,
        },
        filter: {
          name: value,
        },
      });

      setSingleSearchInUse(true);

      return;
    }

    setPaginationCount(maxCount);
    setSingleSearchInUse(false);

    getSportEvents({
      pagination: {
        page: 0,
        rowsPerPage: 10,
      },
    });
  };

  const debouncedChangeHandler = useMemo(
    () => debounce(changeHandler, 300),
    []
  );

  if (!rows) {
    return null;
  }

  return (
    rows && (
      <Styled.Container>
        <Styled.FiltersContainer>
          <form onSubmit={filterForm.handleSubmit}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={3}>
                <TextField
                  name="search"
                  onChange={debouncedChangeHandler}
                  defaultValue=""
                  variant="outlined"
                  label="Search events by name"
                  size="small"
                />
              </Grid>
            </Grid>
          </form>
        </Styled.FiltersContainer>

        <Table
          page={0}
          rows={rows}
          columns={columns}
          pageSize={pageSize}
          onPageChange={handlePageChange}
          rowCount={paginationCount}
          hasActionButtons={true}
        />
      </Styled.Container>
    )
  );
};

export default SportEventsTable;
