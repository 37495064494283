import { ExpandMore, ExpandLess, LabelImportant } from "@mui/icons-material";
import {
  List,
  Collapse,
  ListItemText,
  ListItemButton,
  ListItemIcon,
} from "@mui/material";
import { useState, createElement, useEffect } from "react";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { SidebarRoute, SIDEBAR_ROUTES } from "../../routes";

import { Styled } from "./Sidebar.styles";

export const getActiveRoute = (
  routes: SidebarRoute[],
  previousRoute: string,
  currentPath: string
): string => {
  for (let i = 0; i < routes.length; i += 1) {
    if (routes[i].subRoutes) {
      const activeSubRoute = getActiveRoute(
        routes[i].subRoutes as SidebarRoute[],
        previousRoute,
        currentPath
      );

      if (activeSubRoute !== previousRoute) {
        return activeSubRoute;
      }
    } else {
      const { pathname } = routes[i];

      if (
        pathname &&
        (pathname === "/"
          ? currentPath === pathname
          : currentPath.includes(pathname))
      ) {
        return routes[i].name;
      }
    }
  }

  return previousRoute;
};

export const getInitialSidebarCollapsedRoute = (
  sidebarRoutes: SidebarRoute[],
  activeItemName: string
): string => {
  for (let i = 0; i < sidebarRoutes.length; i += 1) {
    // Check if one of the sub routes is currently active
    if (
      sidebarRoutes[i].subRoutes?.filter(({ name }) => name === activeItemName)
        .length
    ) {
      return sidebarRoutes[i].name;
    }
  }

  return "";
};

const Sidebar: React.FC = () => {
  const path = useLocation().pathname;
  const location = useNavigate();

  const [activeRouteName, setActiveRouteName] = useState(
    getActiveRoute(SIDEBAR_ROUTES, "", path)
  );

  useEffect(() => {
    setActiveRouteName(getActiveRoute(SIDEBAR_ROUTES, activeRouteName, path));
  }, [activeRouteName, location, path]);

  const [expandedMenuStates, setExpandedMenuStates] = useState<
    Record<string, boolean>
  >({
    [getInitialSidebarCollapsedRoute(SIDEBAR_ROUTES, activeRouteName)]: true,
  });

  const handleGroupClick = (routeName: SidebarRoute["name"]) => {
    setExpandedMenuStates({
      ...expandedMenuStates,
      [routeName]: !expandedMenuStates[routeName],
    });
  };

  const renderSidebarItems = (routes: SidebarRoute[], isSubRoutes?: boolean) =>
    routes.map(({ name, icon, pathname, subRoutes }) => {
      const isSelected =
        !!pathname &&
        (pathname === "/" ? path === pathname : path.includes(pathname));

      if (!subRoutes && pathname) {
        return (
          <Styled.Link to={pathname} key={name}>
            <ListItemButton
              selected={isSelected}
              {...(isSubRoutes && { sx: { pl: 4 } })}
            >
              <ListItemIcon>
                {icon ? createElement(icon) : <LabelImportant />}
              </ListItemIcon>
              <ListItemText primary={name} />
            </ListItemButton>
          </Styled.Link>
        );
      }

      return (
        <React.Fragment key={name}>
          <ListItemButton
            onClick={() => {
              handleGroupClick(name);
            }}
          >
            <ListItemIcon>
              {icon ? createElement(icon) : <LabelImportant />}
            </ListItemIcon>
            <ListItemText primary={name} />
            {expandedMenuStates[name] ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>

          <Collapse in={expandedMenuStates[name]} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {renderSidebarItems(subRoutes as SidebarRoute[], true)}
            </List>
          </Collapse>
        </React.Fragment>
      );
    });

  return <List component="nav">{renderSidebarItems(SIDEBAR_ROUTES)}</List>;
};

export default Sidebar;
